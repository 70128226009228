/* You can add global styles to this file, and also import other style files */

/*------------------------------------------------------------------
[Table of contents]

1. Initiate Css
	1. Reset
	2. Wordpress Style ( caption / align style )
2. Theme Style
	1. Header
	2. Sidebar
	3. Footer
3.) Content Item
4.) Widget
5.) Default Plugin
6.) 10335 Soccer Plugin 
-------------------------------------------------------------------*/

/*--- reset ---*/
/*--- reset5 © 2011 opensource.736cs.com MIT ---*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
audio,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
mark,
menu,
meter,
nav,
output,
progress,
section,
summary,
time,
video {
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  margin: 0;
  padding: 0;
}
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section,
blockquote {
  display: block;
}
nav ul {
  list-style: none;
}
ol {
  list-style: decimal;
}
ul {
  list-style: disc;
}
ul ul {
  list-style: circle;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
ins {
  text-decoration: underline;
}
del {
  text-decoration: line-through;
}
mark {
  background: none;
}
abbr[title],
dfn[title],
acronym[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
input[type='submit'],
input[type='button'],
button {
  margin: 0;
  padding: 0;
}
input,
select,
a img {
  vertical-align: middle;
}

b,
strong {
  font-weight: bold;
}
i,
em {
  font-style: italic;
}

/*--- clear ---*/
.clear {
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
  zoom: 1;
}
.clear-fix {
  zoom: 1;
}
.clear-fix:after {
  content: '.';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

/*--- wordpress core ---*/
.alignnone {
  margin: 5px 20px 20px 0;
}
.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}
.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}
.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}
.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}
a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}
a img.alignnone {
  margin: 5px 20px 20px 0;
}
a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}
a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.wp-caption {
  background: #fff;
  border-width: 1px;
  border-style: solid;
  max-width: 96%;
  padding: 5px 3px 10px;
  text-align: center;
}
.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}
.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}
.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}
.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}
.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}
.sticky {
}
.bypostauthor {
}
dl {
  width: 100%;
  overflow: hidden;
}
dt {
  float: left;
  width: 30%;
  clear: left;
  margin-bottom: 12px;
}
dd {
  float: left;
  width: 70%;
  margin-bottom: 15px;
}
big {
  font-size: 15px;
}
code,
kbd {
  border: 1px dotted;
  padding: 5px 12px;
  margin: 0px 10px;
}
sup {
  vertical-align: super;
  font-size: 11px;
}
sub {
  vertical-align: sub;
  font-size: 11px;
}

.gallery-item .gallery-icon {
  margin-right: 10px;
}
i {
  margin-right: 5px;
}
a {
  text-decoration: none;
}
p {
  margin-bottom: 20px;
}
ul,
ol {
  margin-bottom: 20px;
  margin-left: 30px;
}
body {
  line-height: 1.7;
  font-family: Arial, Helvetica, sans-serif;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  margin-bottom: 18px;
  font-weight: 400;
}

.post-password-form input[type='submit'] {
  margin-left: 20px;
  margin-bottom: 0px;
}
.post-password-form input[type='password'] {
  margin-left: 10px;
  padding: 10px 15px;
}

/*----------------------------------------------------------------------*/
/*---------------             THEME STYLE             ------------------*/
/*----------------------------------------------------------------------*/

/*--- structure ---*/
.container {
  padding: 0px;
  margin: 0px auto; /* position: relative; */
}
.column,
.columns {
  float: left;
  position: relative;
  min-height: 1px;
}

.one-ninth {
  width: 11.111%;
}
.one-eighth {
  width: 12.5%;
}
.one-seventh {
  width: 14.285%;
}
.one-fifth {
  width: 20%;
}
.two-fifth {
  width: 40%;
}
.three-fifth {
  width: 60%;
}
.four-fifth {
  width: 80%;
}

.one {
  width: 8.333%;
}
.two {
  width: 16.666%;
}
.three {
  width: 25%;
}
.four {
  width: 33.333%;
}
.five {
  width: 41.666%;
}
.six {
  width: 50%;
}
.seven {
  width: 58.334%;
}
.eight {
  width: 66.667%;
}
.nine {
  width: 75%;
}
.ten {
  width: 83.334%;
}
.eleven {
  width: 91.667%;
}
.twelve {
  width: 100%;
}

.ie7 .sf-mega-section.three {
  width: 24%;
}

.granite-item {
  margin: 0px 15px 20px;
  min-height: 1px;
}
.granite-full-size-wrapper .granite-item-no-space {
  margin-bottom: 0px;
}
.granite-item-no-space {
  margin: 0px 20px 40px;
}
.granite-item-no-space .granite-item {
  margin: 0px;
}
.granite-item.granite-main-content {
  margin-bottom: 55px;
}
.granite-item.granite-divider-item {
  margin-bottom: 50px;
}
.section-container,
.granite-item-start-content,
.granite-parallax-wrapper,
.granite-color-wrapper {
  padding-top: 60px;
}
.granite-color-wrapper,
.granite-parallax-wrapper,
.granite-full-size-wrapper {
  padding-bottom: 40px;
}
.granite-full-size-wrapper .granite-item,
.granite-full-size-wrapper .granite-item-no-space {
  margin-left: 0px;
  margin-right: 0px;
}
.granite-half-bg {
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-size: cover;
  background-position: center;
}
.granite-half-bg.granite-bg-solid {
  left: 0px;
  right: 50%;
}
.granite-half-bg.granite-solid-bg {
  left: 50%;
  right: 50px;
}
.granite-half-bg-wrapper .six.columns {
  left: 30px;
  right: 30px;
}
.granite-half-bg.granite-solid-bg {
  left: 52%;
  right: 0px;
}

.ie7 .granite-item-start-content {
  overflow: hidden;
}
audio {
  width: 100%;
}
.mejs-container .mejs-controls .mejs-horizontal-volume-slider {
  position: relative;
  clear: both;
  float: right;
  top: -31px;
}
img,
video {
  max-width: 100%;
  height: auto;
  width: auto; /* for ie8 */
}
.ms-container video {
  max-width: none;
}
input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  border: 0px;
  outline: none;
  padding: 6px;
  max-width: 100%;
}

/*--- transition / color animation  ---*/
a {
  transition: color 300ms, background-color 300ms, opacity 300ms;
  -moz-transition: color 300ms, background-color 300ms, opacity 300ms; /* FF3.7+ */
  -o-transition: color 300ms, background-color 300ms, opacity 300ms; /* Opera 10.5 */
  -webkit-transition: color 300ms, background-color 300ms, opacity 300ms; /* Saf3.2+, Chrome */
}

/*--- boxed style ---*/
.body-wrapper {
  overflow: hidden;
}
.body-wrapper.granite-boxed-style {
  margin: 0px auto;
  -moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
}

img.granite-full-boxed-background {
  min-height: 100%;
  min-width: 1024px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 1024px) {
  img.granite-full-boxed-background {
    left: 50%;
    margin-left: -512px;
  }
}

.content-wrapper {
  position: relative;
  z-index: 89;
  margin-top: 112px;
}

/*--- top navigation ---*/
.top-navigation-wrapper {
  position: relative;
  z-index: 98;
  padding: 11px 0px;
  font-size: 13px;
  line-height: 18px;
}
.top-navigation-left {
  float: left;
  margin: 0px 15px;
}
.top-navigation-right {
  float: right;
  margin: 0px 15px;
}

.top-social-wrapper .social-icon {
  font-size: 14px;
  display: inline;
  margin-left: 6px;
}
.top-social-wrapper .social-icon a {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.top-social-wrapper .social-icon a:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

/*--- search form ---*/
.granite-navigation-substitute {
  position: relative;
  z-index: 91;
}

#granite-menu-search-button {
  margin: 2px 0px 0px 30px;
  float: right;
  cursor: pointer;
  max-width: 15px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.granite-header-transparent #granite-menu-search-button {
  opacity: 1;
  filter: alpha(opacity=100);
}
#granite-menu-search {
  position: absolute;
  top: 100%;
  right: 15px;
  width: 250px;
  display: none;
}
#granite-menu-search input[type='text'] {
  margin: 0px;
  background: transparent;
  padding: 20px 23px;
}
#granite-menu-search input[type='submit'] {
  display: none;
}
.granite-menu-search-button-sep {
  position: absolute;
}

/*--- top login menu---*/
.granite-login-form input[type='text'],
.granite-login-form input[type='email'],
.granite-login-form input[type='password'] {
  width: 200px;
  padding: 12px;
  margin-bottom: 5px;
}
.granite-login-form label {
  display: block;
  margin-bottom: 4px;
}
.granite-login-form p {
  margin-bottom: 0px;
  margin-top: 9px;
  overflow: hidden;
}
.granite-login-form p:first-child {
  margin-top: 0px;
}
.granite-login-form input[type='submit'] {
  margin-bottom: 0px;
}
.granite-login-form input[type='checkbox'] {
  margin: 2px 10px 0px 0px;
  float: left;
}
.granite-login-form .login-remember {
  padding-top: 3px;
}

/*--- header ---*/
.granite-woo-button-overlay {
  width: 65px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}
.granite-top-woocommerce-button {
  cursor: pointer;
  width: 15px;
  text-align: right;
  font-size: 10px;
  position: relative;
  margin: 3px 25px 0px;
}
.granite-top-woocommerce-button img {
  opacity: 1;
  filter: alpha(opacity=100);
}
.granite-top-woocommerce-button i {
  font-size: 14px;
}
.granite-top-woocommerce-button .granite-cart-item-count {
  position: absolute;
  right: -7px;
  top: -7px;
}
.granite-top-woocommerce-wrapper {
  float: right;
}
.granite-top-woocommerce {
  text-align: center;
  display: none;
  top: 100%;
  position: absolute;
  right: 15px;
}
.granite-top-woocommerce .granite-top-woocommerce-inner {
  padding: 30px 40px 23px;
}
.granite-top-woocommerce .granite-cart-count {
  margin-bottom: 8px;
}
.granite-top-woocommerce .granite-cart-amount {
  margin-bottom: 20px;
}
.granite-top-woocommerce-inner > a {
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

html .woocommerce .granite-widget-title {
  font-size: 12px;
}


.granite-logo-container,
.granite-header-container {
  position: relative;
  z-index: 97;
}
.granite-logo {
  float: left;
  margin-left: 0px;
  margin-right: 15px;
}
.granite-logo h1 {
  margin-bottom: 0px;
  line-height: 0;
}

.granite-fixed-header {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 999;
}
.granite-fixed-header .granite-logo {
  margin-top: 16px;
  margin-bottom: 16px;
}
.granite-fixed-header .granite-logo img {
  max-height: 38px;
  width: auto;
}
.granite-fixed-header .granite-navigation-wrapper {
  margin-top: 25px;
}
.granite-fixed-header .granite-main-menu > li > a {
  padding-bottom: 18px;
}
.granite-fixed-header .granite-navigation-slide-bar {
  display: none;
}
.granite-fixed-header.granite-header-inner {
  -moz-box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
}
.granite-fixed-header .granite-navigation-wrapper .granite-main-menu > li > a {
  font-size: 12px;
  padding-bottom: 23px;
}
.admin-bar .granite-fixed-header {
  top: 32px;
}

.granite-navigation-gimmick {
  position: absolute;
  bottom: 0px;
  height: 3px;
}
.granite-navigation-wrapper {
  margin: 0px 15px;
  float: right;
}
.granite-navigation-wrapper .granite-navigation-container {
  position: relative;
  line-height: 0;
}
.granite-navigation-wrapper .granite-main-menu > li {
  padding: 0px;
}
.granite-navigation-wrapper .granite-main-menu > li > a {
  font-size: 13px;
  padding: 0px 33px 45px 0px;
  margin: 0px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
.granite-navigation-wrapper .granite-main-menu > li:last-child > a,
.granite-navigation-wrapper .granite-main-menu > li:last-child > a.sf-with-ul,
.granite-navigation-wrapper .granite-main-menu > li:last-child > a.sf-with-ul-pre {
  padding-right: 0px;
}
.granite-navigation-wrapper .granite-main-menu > li > a.sf-with-ul-pre,
.granite-navigation-wrapper .granite-main-menu > li > a.sf-with-ul {
  padding-right: 33px;
}
.granite-navigation-wrapper .granite-main-menu > li > a.sf-with-ul:after {
  top: 11px;
  right: 0px;
  display: none;
}
.granite-donate-button {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  width: 100px;
  margin-left: 30px;
  float: left;
  text-align: center;
  cursor: pointer;
}
.granite-button-donate-text {
  position: relative;
}
.granite-button-overlay {
  width: 100px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

.granite-page-title-wrapper {
  padding: 85px 0px 80px;
  position: relative;
  z-index: 89;
  line-height: 1;
  text-align: center;
  background-image: url('/assets/images/page-title-background.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.granite-page-title-wrapper .granite-page-title-overlay {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: #000;
  opacity: 0;
  filter: alpha(opacity=0);
}
.granite-page-title-wrapper .granite-page-title-container {
  position: relative;
}
.granite-page-title-wrapper .granite-page-title {
  font-size: 45px;
  margin: 0px 15px;
  display: block;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.granite-page-title-wrapper .granite-page-caption {
  font-size: 20px;
  margin: 12px 17px 0px;
  display: block;
  font-style: italic;
}
.granite-page-title-wrapper .granite-page-title-gimmick {
  font-size: 20px;
  display: block;
  margin-top: 20px;
}
.granite-full-size-wrapper .granite-item-title-head {
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
}


/*--- header shortcode ---*/
.granite-heading-shortcode.with-background {
  padding: 8px 19px;
  display: inline-block;
}
.granite-heading-shortcode i {
  margin-right: 10px;
}

/*--- wrapper item ---*/
.granite-color-wrapper,
.granite-parallax-wrapper {
  overflow: hidden;
  background-position: center 0px;
  background-repeat: repeat;
  position: relative;
}

/*--- sidebar ---*/
.with-sidebar-container .with-sidebar-content {
  float: right;
}
.granite-widget-title {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.granite-sidebar .granite-widget-title {
  display: inline-block;
  padding-right: 4px;
}
.widget.granite-widget {
  margin-bottom: 50px;
}

/*--- stunning-text ---*/
.granite-stunning-item .stunning-item-title {
  font-weight: 300;
  font-size: 30px;
  margin-bottom: 10px;
}

.granite-stunning-item.granite-stunning-left.granite-button-on {
  position: relative;
  padding-right: 150px;
  font-size: 16px;
}
.granite-stunning-item.granite-stunning-left .stunning-item-button {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -21px;
  margin-right: 0px;
}

.granite-stunning-item.granite-stunning-small-left.granite-button-on {
  position: relative;
  padding-right: 150px;
}
.granite-stunning-item.granite-stunning-small-left .stunning-item-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
}
.granite-stunning-item.granite-stunning-small-left .stunning-item-button {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -21px;
  margin-right: 0px;
}

.granite-stunning-item.granite-stunning-center {
  text-align: center;
}
.granite-stunning-item.granite-stunning-center .stunning-item-button {
  margin: 20px 9px 0px;
}
.granite-stunning-item.granite-stunning-center .stunning-item-title {
  font-size: 39px;
  font-weight: 800;
}
.granite-stunning-item.granite-stunning-center .stunning-item-caption {
  font-size: 21px;
}

.granite-stunning-text-item.type-center {
  text-align: center;
  margin-bottom: 35px;
}
.granite-stunning-text-item.type-center .stunning-text-title {
  font-size: 33px;
  font-weight: bold;
  margin-bottom: 25px;
}
.granite-stunning-text-item.type-center .stunning-text-caption {
  font-size: 16px;
}
.granite-stunning-text-item.type-center .stunning-text-button {
  margin: 15px 0px 0px;
}

.granite-stunning-text-item.type-normal.granite-button-on {
  padding-right: 190px;
  position: relative;
}
.granite-stunning-text-item.type-normal .stunning-text-title {
  font-size: 29px;
  margin-bottom: 18px;
}
.granite-stunning-text-item.type-normal .stunning-text-caption {
  font-size: 16px;
}
.granite-stunning-text-item.type-normal .stunning-text-button {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -22px;
  margin-right: 0px;
}

.granite-stunning-text-item.with-padding {
  padding: 35px 35px 15px;
}
.granite-stunning-text-item.with-padding.with-border {
  border-width: 3px;
  border-style: solid;
}
.granite-stunning-text-item.with-padding.granite-button-on {
  padding-right: 200px;
}
.granite-stunning-text-item.with-padding .stunning-text-button {
  right: 40px;
}
.granite-stunning-text-item.with-padding .stunning-text-caption {
  margin-bottom: 20px;
}

/*--- footer ---*/
.footer-wrapper {
  position: relative;
  background-repeat: no-repeat;
  background-position: right top;
}
.footer-wrapper a {
  cursor: pointer;
}
.footer-wrapper .widget.granite-widget {
  margin-bottom: 45px;
}
.footer-wrapper .granite-widget-title {
  margin-bottom: 30px;
}
.footer-container {
  padding-top: 50px;
}
.ie7 .footer-wrapper {
  overflow: hidden;
}

.copyright-left {
  margin: 0px 15px;
  float: left;
}
.copyright-right {
  margin: 0px 15px;
  float: right;
}
.copyright-wrapper {
  border-top-width: 1px;
  border-top-style: solid;
}
.copyright-container {
  padding: 15px 0px;
}

/*--- shortcode ---*/
.granite-shortcode-wrapper {
  margin-left: -15px;
  margin-right: -15px;
}

/*--- 404 page not found ---*/
.page-not-found-block {
  text-align: center;
  padding: 50px 50px 60px;
  margin: 50px 0px 50px;
}
.page-not-found-block .page-not-found-title {
  font-size: 45px;
  line-height: 1;
  margin-bottom: 17px;
}
.page-not-found-block .page-not-found-caption {
  font-size: 20px;
  margin-bottom: 35px;
}
.page-not-found-block .page-not-found-icon {
  font-size: 89px;
  line-height: 1;
  margin-bottom: 20px;
}
.page-not-found-search {
  max-width: 300px;
  margin: 0px auto;
}
.page-not-found-search .gdl-search-form {
  padding-right: 30px;
}
.page-not-found-search .gdl-search-form input[type='text'] {
  padding: 12px 15px;
  font-size: 15px;
  border: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.page-not-found-search .gdl-search-form input[type='submit'] {
  background-image: url('/assets/images/light/icon-search.png');
}

body.search .page-not-found-block {
  margin-top: 0px;
}

/*----------------------------------------------------------------------*/
/*---------------             CONTENT ITEM            ------------------*/
/*----------------------------------------------------------------------*/

/*--- item title ---*/
.granite-item-title-wrapper.granite-item {
  margin-bottom: 30px;
  position: relative;
}
.granite-item-title-wrapper .granite-item-title {
  font-size: 30px;
  margin-bottom: 0px;
  font-weight: 800;
}
.granite-item-title-wrapper .granite-item-title i {
  margin-right: 12px;
}
.granite-item-title-wrapper .granite-item-title img {
  margin-right: 14px;
  vertical-align: middle;
  max-width: 37px;
  margin-top: -1px;
  margin-bottom: 3px;
}
.granite-item-title-wrapper .granite-item-title-caption {
  margin-top: 8px;
  font-size: 20px;
  font-style: italic;
}
.granite-item-title-wrapper .granite-item-title-link {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 8px;
  display: inline-block;
}
.granite-item-title-wrapper .granite-item-title-link:after {
  content: '\f101';
  font-family: fontAwesome;
  font-weight: normal;
  margin-left: 8px;
}
.granite-item-title-wrapper.granite-medium.granite-left
  .granite-item-title-link
  .granite-item-title-wrapper.granite-medium.granite-left-divider
  .granite-item-title-link {
  margin-top: 11px;
}
.granite-item-title-wrapper.granite-large.granite-left .granite-item-title-link,
.granite-item-title-wrapper.granite-large.granite-left-divider .granite-item-title-link {
  margin-top: 18px;
}
.granite-item-title-wrapper.granite-extra-large.granite-left .granite-item-title-link,
.granite-item-title-wrapper.granite-extra-large.granite-left-divider .granite-item-title-link {
  margin-top: 37px;
}

.granite-item-title-wrapper.granite-center {
  text-align: center;
}
.granite-item-title-wrapper.granite-center .granite-item-title-link:after {
  display: none;
}
.granite-item-title-wrapper.granite-left .granite-item-title-link,
.granite-item-title-wrapper.granite-left-divider .granite-item-title-link {
  float: right;
}
.granite-item-title-wrapper.granite-left .granite-item-title,
.granite-item-title-wrapper.granite-left .granite-item-title-caption,
.granite-item-title-wrapper.granite-left-divider .granite-item-title,
.granite-item-title-wrapper.granite-left-divider .granite-item-title-caption {
  display: inline;
  margin-right: 20px;
}
.granite-item-title-wrapper.granite-left-divider {
  padding-bottom: 10px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}
.granite-item-title-wrapper.granite-small .granite-item-title {
  font-size: 20px;
}
.granite-item-title-wrapper.granite-medium .granite-item-title {
  font-size: 24px;
}
.granite-item-title-wrapper.granite-extra-large .granite-item-title {
  font-size: 52px;
  letter-spacing: 3px;
}

.granite-title-item {
  margin-bottom: 20px;
}
.granite-title-item .granite-item-title-wrapper.granite-item {
  margin-bottom: 0px;
}

.granite-item-title-wrapper.granite-nav-container .granite-item-title {
  display: inline;
  margin-right: 20px;
}
.granite-item-title-wrapper.granite-nav-container .granite-flex-prev {
  font-size: 19px;
  margin-right: 9px;
  cursor: pointer;
}
.granite-item-title-wrapper.granite-nav-container .granite-flex-next {
  font-size: 19px;
  margin-left: 8px;
  margin-right: 0px;
  cursor: pointer;
}
.granite-item-title-wrapper.granite-nav-container.granite-left .granite-nav-title,
.granite-item-title-wrapper.granite-nav-container.granite-left-divider .granite-nav-title {
  float: right;
  margin-top: 6px;
  margin-left: 20px;
}

.granite-item-title-nav.granite-nav-container {
  margin: 0px 20px 30px;
  line-height: 1;
}
.granite-item-title-nav i {
  font-size: 35px;
  vertical-align: text-top;
  margin: 0px 11px;
}
.granite-item-title-nav .granite-flex-prev,
.granite-item-title-nav .granite-flex-next {
  font-size: 20px;
  cursor: pointer;
}
.granite-item-title-nav img {
  max-width: 40px;
  vertical-align: text-top;
  margin: 0px 20px;
}
.granite-item-title-nav.granite-center {
  text-align: center;
}

/*--- accordion / toggle ---*/
.accordion-tab > .accordion-content {
  display: none;
}
.accordion-tab.active > .accordion-content {
  display: block;
}

.granite-accordion-item.style-1 .accordion-tab {
  overflow: hidden;
  padding: 11px 0px;
  border-top-width: 1px;
  border-top-style: solid;
}
.granite-accordion-item.style-1 .accordion-tab:first-child {
  border: 0px;
  padding-top: 0px;
}
.granite-accordion-item.style-1 .accordion-title {
  font-weight: normal;
  font-size: 17px;
  line-height: 33px;
  margin-bottom: 0px;
  cursor: pointer;
  transition: color 200ms;
  -moz-transition: color 200ms;
  -o-transition: color 200ms;
  -webkit-transition: color 200ms;
}
.granite-accordion-item.style-1 .accordion-title i {
  margin-right: 20px;
  font-size: 13px;
  width: 9px;
  height: 13px;
  padding: 12px 14px 10px;
  display: inline-block;
}
.granite-accordion-item.style-1 .accordion-content {
  padding-top: 12px;
  margin-left: 57px;
}
.granite-accordion-item.style-1 .accordion-title span {
  overflow: hidden;
  display: block;
}
.granite-accordion-item.style-1 .accordion-title i {
  float: left;
  transition: color 200ms, background-color 200ms;
  -moz-transition: color 200ms, background-color 200ms;
  -o-transition: color 200ms, background-color 200ms;
  -webkit-transition: color 200ms, background-color 200ms;
}

.granite-accordion-item.style-2 .accordion-tab {
  overflow: hidden;
  padding-bottom: 4px;
}
.granite-accordion-item.style-2 .accordion-title {
  padding-bottom: 16px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 0px;
  cursor: pointer;
}
.granite-accordion-item.style-2 .accordion-title i {
  margin-right: 20px;
  font-size: 13px;
}
.granite-accordion-item.style-2 .accordion-content {
  margin-left: 31px;
}

/*--- button ---*/
input[type='submit'],
input[type='button'] {
  -webkit-appearance: none;
}
.granite-button,
input[type='button'],
input[type='submit'] {
  display: inline-block;
  font-size: 12px;
  padding: 14px 27px;
  margin-bottom: 15px;
  margin-right: 15px;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  border: none;
  letter-spacing: 1px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.granite-button.small {
  font-size: 11px;
  padding: 11px 17px;
}
.granite-button.large {
  font-size: 13px;
  padding: 15px 28px;
  font-weight: 800;
}
.granite-button.with-border {
  border-bottom-width: 3px;
  border-bottom-style: solid;
}
.granite-button .granite-icon {
  margin-right: 5px;
  letter-spacing: 1px;
}
.granite-border-button {
  padding: 11px 25px;
  border-width: 2px;
  border-style: solid;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
}

/*--- action-ads ---*/
.granite-action-ads-item {
  padding: 50px 40px 37px;
  overflow: hidden;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
.granite-action-ads-item .action-ads-title {
  font-size: 36px;
  margin-bottom: 2px;
  font-weight: 700;
}
.granite-action-ads-item .action-ads-caption {
  font-size: 22px;
  margin-bottom: 17px;
}
.granite-action-ads-item .action-ads-button {
  margin-top: 3px;
}
.granite-action-ads-item .action-ads-divider {
  width: 35px;
  height: 3px;
  margin-bottom: 18px;
}

/*--- about-us ---*/
.granite-normal .about-us-caption {
  font-size: 15px;
  font-style: italic;
  margin-bottom: 25px;
}
.granite-normal .about-us-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 13px;
}
.granite-normal .about-us-read-more {
  margin-top: 12px;
  display: inline-block;
}

.granite-small-title .about-us-caption {
  font-size: 16px;
  font-style: italic;
  margin-bottom: 25px;
}
.granite-small-title .about-us-title {
  font-size: 21px;
  font-weight: 800;
  margin-bottom: 6px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.granite-small-title .about-us-read-more {
  margin-top: 6px;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}

.granite-plain .about-us-caption {
  font-size: 15px;
  margin-bottom: 6px;
  font-style: italic;
}
.granite-plain .about-us-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 25px;
}
.granite-plain .about-us-read-more {
  margin-top: 4px;
  display: inline-block;
}

.granite-plain-large .about-us-caption {
  font-size: 22px;
  margin-bottom: 6px;
}
.granite-plain-large .about-us-title {
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 24px;
  line-height: 1;
}
.granite-plain-large .about-us-read-more {
  margin-bottom: 0px;
  margin-top: 6px;
}

.granite-title-left .about-us-title-wrapper {
  float: left;
  width: 27%;
  text-align: right;
  margin-right: 80px;
}
.granite-title-left .about-us-title {
  font-size: 35px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 22px;
}
.granite-title-left .about-us-caption {
  font-size: 35px;
  font-weight: bold;
  line-height: 1.2;
}
.granite-title-left .about-us-title-divider {
  border-top-width: 4px;
  border-top-style: solid;
  width: 45px;
  margin-left: auto;
}
.granite-title-left .about-us-read-more {
  margin-bottom: 0px;
  margin-top: 6px;
}
.granite-title-left .about-us-content-wrapper {
  overflow: hidden;
}

/*--- column service ---*/
.granite-column-service-item .column-service-icon {
  margin-right: 15px;
  font-size: 30px;
  line-height: 18px;
}
.granite-column-service-item .column-service-icon i {
  margin: 0px;
  vertical-align: middle;
}
.granite-column-service-item .column-service-title {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 800;
  line-height: 25px;
}
.granite-column-service-item .column-service-read-more {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  display: block;
}

.granite-column-service-item.granite-type-1 .column-service-icon {
  float: left;
  margin-right: 25px;
}
.granite-column-service-item.granite-type-1 .column-service-icon.granite-skin-box {
  background: transparent;
}
.granite-column-service-item.granite-type-1 .column-service-content-wrapper {
  overflow: hidden;
}
.granite-column-service-item.granite-type-1 .column-service-title {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 20px;
}
.granite-column-service-item.granite-type-1 .column-service-image {
  max-width: 35px;
  float: left;
  margin-right: 25px;
}

.granite-column-service-item.granite-type-1-caption .column-service-icon {
  float: left;
  margin-right: 25px;
}
.granite-column-service-item.granite-type-1-caption .column-service-icon.granite-skin-box {
  background: transparent;
}
.granite-column-service-item.granite-type-1-caption .column-service-content-wrapper {
  overflow: hidden;
}
.granite-column-service-item.granite-type-1-caption .column-service-title {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 4px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
}
.granite-column-service-item.granite-type-1-caption .column-service-caption {
  font-size: 14px;
  margin-bottom: 16px;
  font-style: italic;
}
.granite-column-service-item.granite-type-1-caption .column-service-image {
  max-width: 48px;
  float: left;
  margin-right: 25px;
}

.granite-column-service-item.granite-type-2 {
  text-align: center;
  padding: 47px 30px 45px;
}
.granite-column-service-item.granite-type-2 .column-service-icon {
  width: 26px;
  height: 26px;
  font-size: 18px;
  padding: 5px;
  line-height: 26px;
  text-align: center;
  margin: 0px auto 28px;
  border-width: 2px;
  border-style: solid;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.granite-column-service-item.granite-type-2 .column-service-icon i {
  font-weight: bold;
}
.granite-column-service-item.granite-type-2 .column-service-image {
  margin-bottom: 25px;
}
.granite-column-service-item.granite-type-2 .column-service-image img,
.granite-column-service-item.granite-type-2-bg .column-service-image img {
  max-width: 50px;
}

.granite-column-service-item.granite-type-2-bg {
  text-align: center;
  margin-left: 0px;
  margin-right: 0px;
  padding: 47px 30px 45px;
}
.granite-column-service-item.granite-type-2-bg .column-service-icon {
  width: 26px;
  height: 26px;
  font-size: 18px;
  padding: 5px;
  line-height: 26px;
  text-align: center;
  margin: 0px auto 38px;
  border-width: 2px;
  border-style: solid;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.granite-column-service-item.granite-type-2-bg .column-service-icon i {
  font-weight: bold;
}
.granite-column-service-item.granite-type-2-bg .column-service-image {
  margin-bottom: 28px;
}

.granite-column-service-item.granite-type-3 {
  text-align: left;
}
.granite-column-service-item.granite-type-3 .column-service-title {
  margin-bottom: 13px;
}
.granite-column-service-item.granite-type-3 .column-service-icon {
  text-align: center;
  margin: 0px auto 28px;
}
.granite-column-service-item.granite-type-3 .column-service-image {
  text-align: center;
  margin-bottom: 25px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  overflow: hidden;
  font-size: 0px;
}

/*--- service with image ---*/
.granite-service-with-image-item .service-with-image-thumbnail {
  max-width: 190px;
  float: left;
  margin-right: 40px;
  background: #fff;
  padding: 7px;
  border-width: 1px;
  border-style: solid;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}
.granite-service-with-image-item .service-with-image-thumbnail img {
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}
.granite-service-with-image-item .service-with-image-title {
  font-size: 18px;
  margin-top: 35px;
}
.granite-service-with-image-item .service-with-image-content-wrapper {
  overflow: hidden;
}

.granite-service-with-image-item.granite-right {
  text-align: right;
}
.granite-service-with-image-item.granite-right .service-with-image-thumbnail {
  float: right;
  margin-left: 40px;
  margin-right: 0px;
}

/*--- service half background ---*/
.granite-service-half-background-item {
  overflow: hidden;
}

.granite-service-half-background-item .granite-half-left .granite-item-margin {
  margin: 0px 30px 0px 15px;
}
.granite-service-half-background-item .granite-half-left {
  width: 50%;
  float: left;
  padding: 70px 0px 999px;
  margin-bottom: -939px;
}
.granite-service-half-background-item .granite-half-left .half-container {
  margin-right: 0px;
  margin-left: auto;
}
.granite-service-half-background-item .granite-left-service-title {
  font-size: 20px;
  font-weight: bold;
}

.granite-service-half-background-item .granite-half-right .granite-item-margin {
  margin: 0px 15px 0px 40px;
}
.granite-service-half-background-item .granite-half-right {
  width: 50%;
  float: left;
  padding: 70px 0px 999px;
  margin-bottom: -939px;
}
.granite-service-half-background-item .granite-half-right .half-container {
  margin-left: 0px;
  margin-right: auto;
}
.granite-service-half-background-item .granite-right-service-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;
}

/*--- feature media ---*/
.granite-feature-media-item .feature-media-button {
  margin-top: 15px;
}
.granite-feature-media-item.granite-left .feature-media-thumbnail {
  float: left;
  margin-right: 55px;
  width: 37%;
}
.granite-feature-media-item.granite-left .feature-media-content-wrapper {
  overflow: hidden;
}
.granite-feature-media-item.granite-center {
  text-align: center;
}
.granite-feature-media-item.granite-center .feature-media-thumbnail {
  max-width: 700px;
  margin: 0px auto 40px;
}
.granite-feature-media-item.granite-center .feature-media-content-wrapper {
  max-width: 700px;
  margin: 0px auto;
}
.granite-feature-media-item.granite-center .feature-media-divider {
  width: 300px;
  max-width: 100%;
  margin: 0px auto 40px;
  border-bottom-width: 5px;
  border-bottom-style: solid;
}
.granite-feature-media-item .granite-item-title-wrapper.granite-item {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 15px;
}

/*--- divider ---*/
.granite-divider {
  border-bottom-width: 1px;
  border-style: solid;
  margin: 0px auto;
  max-width: 100%;
}
.granite-divider.double {
  height: 3px;
  border-top-width: 1px;
}
.granite-divider.dotted {
  border-style: dashed;
}
.granite-divider.double-dotted {
  height: 3px;
  border-top-width: 1px;
  border-style: dashed;
}
.granite-divider.thick {
  border-bottom-width: 3px;
}

.granite-divider-with-icon {
  margin: 0px auto;
  position: relative;
  text-align: center;
}
.granite-divider-with-icon .granite-divider-icon-outer {
  display: inline-block;
  padding: 7px;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.granite-divider-with-icon .granite-divider-icon {
  font-size: 15px;
  line-height: 15px;
  width: 15px;
  text-align: center;
  margin: 0px auto;
  padding: 13px;
  color: #fff;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.granite-divider-with-icon-left,
.granite-divider-with-icon-right {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  position: absolute;
  top: 28px;
}
.granite-divider-with-icon-left {
  left: 0px;
  right: 50%;
  margin-right: 40px;
}
.granite-divider-with-icon-right {
  right: 0px;
  left: 50%;
  margin-left: 40px;
}

/*--- box with icon ---*/
.granite-box-with-icon-item {
  text-align: center;
  padding: 40px 30px 20px;
  position: relative;
}
.granite-box-with-icon-item > i {
  font-size: 45px;
  display: inline-block;
  margin-bottom: 22px;
  margin-right: 0;
}
.granite-box-with-icon-item .box-with-icon-title {
  font-size: 20px;
  margin-bottom: 20px;
  display: block;
}

.granite-box-with-icon-item.pos-left {
  text-align: left;
  padding: 30px 40px 20px;
}
.granite-box-with-icon-item.pos-left > i {
  float: left;
  margin: 5px 25px 0px 0px;
}

.granite-box-with-icon-item.type-circle .box-with-circle-icon {
  padding: 18px;
  display: inline-block;
  width: 22px;
  text-align: center;
  line-height: 22px;
  position: absolute;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
}
.granite-box-with-icon-item.type-circle .box-with-circle-icon > i {
  font-size: 22px;
  margin-right: 0;
}
.granite-box-with-icon-item.type-circle.pos-top {
  padding-top: 50px;
}
.granite-box-with-icon-item.type-circle.pos-top .box-with-circle-icon {
  top: -30px;
  left: 50%;
  margin-left: -30px;
}
.granite-box-with-icon-item.type-circle.pos-left {
  margin-left: 40px;
  padding-left: 50px;
}
.granite-box-with-icon-item.type-circle.pos-left .box-with-circle-icon {
  left: -30px;
  top: 50%;
  margin-top: -30px;
}

/*-- icon --*/
i.granite-icon {
  margin-right: 10px;
}

/*--- frame ---*/
.granite-frame-shortcode {
  line-height: 1;
}
.granite-frame-shortcode.granite-align-left {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}
.granite-frame-shortcode.granite-align-right {
  float: right;
  margin-left: 20px;
  margin-bottom: 20px;
}
.granite-frame-shortcode.granite-align-center {
  text-align: center;
  margin-bottom: 20px;
}
.granite-frame-shortcode .granite-frame-caption {
  display: block;
  text-align: center;
  margin-top: 15px;
}

.granite-frame {
  display: inline-block;
  max-width: 100%;
  position: relative;
}
.granite-frame img {
  display: block;
}
.granite-frame.frame-type-border {
  padding: 6px;
  border-style: solid;
  border-width: 1px;
}
.granite-frame.frame-type-solid {
  padding: 5px;
}
.granite-frame.frame-type-rounded {
  overflow: hidden;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.granite-frame.frame-type-circle {
  overflow: hidden;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.granite-frame.frame-type-solid.frame-type-circle .granite-image-link-shortcode,
.granite-frame.frame-type-border.frame-type-circle .granite-image-link-shortcode {
  overflow: hidden;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.granite-frame.frame-type-solid.frame-type-rounded .granite-image-link-shortcode,
.granite-frame.frame-type-border.frame-type-rounded .granite-image-link-shortcode {
  overflow: hidden;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.granite-image-frame-item {
  text-align: center;
  line-height: 0;
}
.granite-image-frame-item .image-frame-item {
  display: inline-block;
  line-height: 1;
}
.granite-image-frame-item .granite-image-frame-content {
  position: absolute;
  top: 50%;
  right: 60px;
  left: 60px;
  line-height: 1.6;
  text-align: center;
  opacity: 0;
}
.granite-image-frame-item .granite-image-frame-content-title {
  font-size: 18px;
  margin-bottom: 15px;
  display: block;
}
.granite-image-frame-item .granite-image-frame-content-caption {
  font-size: 13px;
}
.granite-image-frame-item .granite-image-frame-content p {
  margin-bottom: 0px;
}
.granite-link-type-content .granite-image-link-shortcode .granite-image-link-gradient {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: url('/assets/images/image-hover-gradient.png') 0px 0px repeat-x;
}

/*--- image link ---*/
.granite-image-link-shortcode {
  position: relative;
}
.granite-image-link-shortcode .granite-image-link-overlay {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.granite-image-link-shortcode .granite-image-link-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 24px;
  line-height: 1;
  margin-left: -12px;
  margin-top: -12px;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: none;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
}
.granite-image-link-shortcode .granite-image-link-icon i {
  margin-right: 0px;
}

/*--- personnel ---*/
.granite-personnel-item.carousel.round-style {
  padding-top: 45px;
}
.granite-personnel-item.carousel .personnel-item {
  margin: 0px 15px;
}
.granite-personnel-item.carousel.box-style .personnel-item {
  padding-top: 35px;
}
.granite-personnel-item.carousel .personnel-item:first-child {
  margin-left: 0px;
}
.personnel-content .granite-icon {
  margin-right: 5px;
}
.granite-personnel-item.box-style .personnel-item-inner {
  padding: 25px 30px;
  position: relative;
}
.granite-personnel-item.box-style .personnel-item-inner:after {
  content: '';
  height: 0px;
  width: 0px;
  border: 12px solid transparent;
  position: absolute;
  right: 0px;
  bottom: -24px;
}
.granite-personnel-item.box-style .personnel-info {
  margin-top: 30px;
  text-align: center;
}
.granite-personnel-item.box-style .personnel-author {
  font-size: 21px;
  font-weight: bold;
}
.granite-personnel-item.box-style .personnel-author-image {
  width: 65px;
  height: 65px;
  position: absolute;
  left: 50%;
  top: -35px;
  margin-left: -35px;
  border-width: 3px;
  border-style: solid;
  overflow: hidden;
  -moz-border-radius: 35px;
  -webkit-border-radius: 35px;
  border-radius: 35px;
}
.granite-personnel-item.box-style .personnel-position {
  font-size: 15px;
}
.granite-personnel-item.box-style .personnel-content {
  text-align: center;
  margin-top: 15px;
}
.granite-personnel-item.box-style .personnel-social {
  text-align: center;
  font-size: 20px;
  margin-top: 14px;
}
.granite-personnel-item.box-style .personnel-social i {
  margin: 0px 1px;
}

.granite-personnel-item.plain-style .personnel-item-inner {
  padding: 25px 30px;
  position: relative;
}
.granite-personnel-item.plain-style .personnel-item-inner:after {
  content: '';
  height: 0px;
  width: 0px;
  border: 12px solid transparent;
  position: absolute;
  right: 0px;
  bottom: -24px;
}
.granite-personnel-item.plain-style .personnel-author-image {
  overflow: hidden;
  font-size: 0px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
.granite-personnel-item.plain-style .personnel-info {
  margin-top: 20px;
}
.granite-personnel-item.plain-style .personnel-author {
  font-size: 19px;
  font-weight: 800;
}
.granite-personnel-item.plain-style .personnel-position {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}
.granite-personnel-item.plain-style .personnel-content {
  margin-top: 17px;
}
.granite-personnel-item.plain-style .personnel-social {
  font-size: 18px;
  margin-top: 14px;
}
.granite-personnel-item.box-style {
  margin-top: 35px;
}
.granite-personnel-item.plain-style .personnel-social i {
  margin: 0px 10px 0px 0px;
}

.granite-personnel-item.plain-style.granite-left .personnel-info,
.granite-personnel-item.plain-style.granite-left .personnel-content {
  text-align: left;
}
.granite-personnel-item.plain-style.granite-left .personnel-author {
  font-size: 19px;
  text-align: left;
  font-weight: 700;
  letter-spacing: 0.7px;
}
.granite-personnel-item.plain-style.granite-left .personnel-position {
  font-size: 12px;
  text-align: left;
}
.granite-personnel-item.plain-style.granite-left .personnel-social {
  font-size: 14px;
  text-align: left;
}

.granite-personnel-item.round-style .personnel-item,
.granite-personnel-item.round-style .personnel-author-image {
  position: relative;
  overflow: hidden;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.granite-personnel-item.round-style .personnel-item img {
  display: block;
}
.granite-personnel-item.round-style .personnel-item-inner {
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
  top: 50%;
  left: 40px;
  right: 40px;
  text-align: center;
  background: transparent;
}
.granite-personnel-item.round-style .personnel-author {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 2px;
}
.granite-personnel-item.round-style .personnel-position {
  font-size: 16px;
  font-style: italic;
}
.granite-personnel-item.round-style .personnel-content {
  margin-top: 20px;
}

/*--- pie chart ---*/
.granite-pie-chart-item {
  text-align: center;
}
.granite-pie-chart-item .granite-chart {
  margin: 0px auto 30px;
}
.granite-pie-chart-item .pie-chart-title {
  font-size: 17px;
  font-weight: 400;
}

/*--- skill bar ---*/
.granite-skill-bar-wrapper {
  margin-bottom: 15px;
}

.granite-skill-bar-wrapper.granite-size-small .granite-skill-bar {
  overflow: hidden;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.granite-skill-bar-wrapper .granite-skill-bar-progress {
  width: 0;
  position: relative;
}
.granite-skill-bar-wrapper.granite-size-small .granite-skill-bar-progress {
  height: 6px;
}
.granite-skill-bar-wrapper.granite-size-medium .granite-skill-bar-progress {
  padding: 8px 0px;
  font-size: 15px;
}
.granite-skill-bar-wrapper.granite-size-large .granite-skill-bar-progress {
  padding: 14px 0px;
  font-size: 17px;
}

.granite-skill-bar-wrapper .skill-bar-content {
  font-weight: bold;
}
.granite-skill-bar-wrapper.granite-size-small .skill-bar-content {
  margin-bottom: 10px;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.granite-skill-bar-wrapper.granite-size-small .skill-bar-percent {
  float: right;
  font-weight: bold;
  font-size: 12px;
}
.granite-skill-bar-wrapper.granite-size-small {
  margin-bottom: 26px;
  padding-right: 10px;
}
.granite-skill-bar-wrapper.granite-size-medium .skill-bar-content {
  padding: 0px 16px;
}
.granite-skill-bar-wrapper.granite-size-medium .skill-bar-content i {
  position: absolute;
  font-size: 15px;
  right: 8px;
  top: 50%;
  margin-top: -8px;
}
.granite-skill-bar-wrapper.granite-size-large .skill-bar-content {
  padding: 0px 22px;
}
.granite-skill-bar-wrapper.granite-size-large .skill-bar-content i {
  position: absolute;
  font-size: 18px;
  right: 16px;
  top: 50%;
  margin-top: -9px;
}

/*--- skill round ---*/
.granite-skill-item-wrapper {
  text-align: center;
  line-height: 1;
}
.granite-skill-item-wrapper img {
  margin: 0px auto 30px;
  display: block;
  max-width: 62px;
}
.granite-skill-item-wrapper .granite-skill-item-title {
  font-size: 37px;
  margin-bottom: 17px;
  font-weight: 800;
  letter-spacing: 1px;
}
.granite-skill-item-wrapper .granite-skill-item-divider {
  width: 17px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  margin: 0px auto 25px;
}
.granite-skill-item-wrapper .granite-skill-item-caption {
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 2px;
}
.granite-skill-item-wrapper.granite-type-2 {
  margin: 0px;
  padding: 80px 20px;
}

/*--- notification ---*/
.granite-notification {
  padding: 13px;
}
.granite-notification > i {
  float: left;
  margin-right: 15px;
  margin-top: 3px;
}
.granite-notification > .notification-content {
  display: block;
  overflow: hidden;
}
.granite-notification.color-background {
  padding: 16px;
}
.granite-notification.color-border {
  border-width: 3px;
  border-style: solid;
}

/*--- tab ---*/
.granite-tab-item.horizontal > .tab-title-wrapper {
  overflow: hidden;
}
.granite-tab-item.horizontal > .tab-title-wrapper .tab-title {
  float: left;
  border-style: solid;
  border-width: 1px 1px 1px 0px;
  font-size: 13px;
  line-height: 19px;
  font-weight: bold;
  padding: 11px 20px;
  margin-bottom: 0px;
  cursor: pointer;
}
.granite-tab-item.horizontal > .tab-title-wrapper .tab-title:first-child {
  border-left-width: 1px;
}
.granite-tab-item.horizontal > .tab-title-wrapper .tab-title i {
  margin-right: 10px;
}
.granite-tab-item.horizontal > .tab-title-wrapper .tab-title.active {
  border-bottom-width: 0px;
  padding-bottom: 12px;
}
.granite-tab-item.horizontal > .tab-content-wrapper {
  margin-top: -1px;
  padding: 20px 20px 0px;
  border-width: 1px;
  border-style: solid;
}
.granite-tab-item.horizontal > .tab-content-wrapper .tab-content {
  display: none;
}
.granite-tab-item.horizontal > .tab-content-wrapper .tab-content.active {
  display: block;
}

.granite-tab-item.vertical > .tab-title-wrapper {
  float: left;
  margin-right: -1px;
  max-width: 40%;
  text-align: center;
}
.granite-tab-item.vertical > .tab-title-wrapper .tab-title {
  border-style: solid;
  border-width: 0px 1px 1px;
  font-size: 13px;
  line-height: 19px;
  font-weight: bold;
  padding: 11px 30px;
  margin-bottom: 0px;
  cursor: pointer;
}
.granite-tab-item.vertical > .tab-title-wrapper .tab-title:first-child {
  border-top-width: 1px;
}
.granite-tab-item.vertical > .tab-title-wrapper .tab-title.active {
  padding-bottom: 12px;
  border-right-width: 0px;
}
.granite-tab-item.vertical > .tab-title-wrapper .tab-title i {
  margin-right: 10px;
}
.granite-tab-item.vertical > .tab-content-wrapper {
  overflow: hidden;
  padding: 20px 20px 0px;
  border-width: 1px;
  border-style: solid;
}
.granite-tab-item.vertical > .tab-content-wrapper .tab-content {
  display: none;
}
.granite-tab-item.vertical > .tab-content-wrapper .tab-content.active {
  display: block;
}

.granite-tab-item.vertical.right > .tab-title-wrapper {
  float: right;
  margin-left: -1px;
  margin-right: 0px;
}
.granite-tab-item.vertical.right > .tab-title-wrapper .tab-title.active {
  border-right-width: 1px;
  border-left-width: 0px;
}

/*--- table ---*/
table {
  width: 100%;
  text-align: center;
}
table tr td {
  padding: 15px 0px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
table tr th {
  padding: 13px 0px;
  font-size: 16px;
  font-weight: normal;
}
table.style-2 {
  border-spacing: 2px;
  border-collapse: separate;
}
table.style-2 tr td {
  border-bottom-width: 0px;
}

/*--- testimonial ---*/
.granite-testimonial-item-wrapper .granite-item-title-nav.granite-nav-container {
  margin-top: 30px;
}
.granite-testimonial-item.carousel .testimonial-item {
  margin: 0px 15px;
}
.granite-testimonial-item.carousel .testimonial-item:first-child {
  margin-left: 0px;
}

.granite-testimonial-item.round-style .testimonial-item {
  padding-bottom: 35px;
}
.granite-testimonial-item.round-style .testimonial-item-inner {
  padding: 30px 30px 60px;
  position: relative;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.granite-testimonial-item.round-style .testimonial-info {
  margin-top: 15px;
}
.granite-testimonial-item.round-style .testimonial-author {
  font-weight: bold;
}
.granite-testimonial-item.round-style .testimonial-author-image {
  width: 65px;
  height: 65px;
  position: absolute;
  left: 50%;
  bottom: -35px;
  margin-left: -35px;
  border-width: 3px;
  border-style: solid;
  overflow: hidden;
  -moz-border-radius: 35px;
  -webkit-border-radius: 35px;
  border-radius: 35px;
}

.granite-testimonial-item.box-style .testimonial-item {
  padding-bottom: 75px;
}
.granite-testimonial-item.box-style .testimonial-item-inner {
  padding: 25px 30px;
  position: relative;
}
.granite-testimonial-item.box-style .testimonial-item-inner:after {
  content: '';
  height: 0px;
  width: 0px;
  border: 12px solid transparent;
  position: absolute;
  right: 0px;
  bottom: -24px;
}
.granite-testimonial-item.box-style .testimonial-info {
  margin-top: 15px;
  text-align: right;
}
.granite-testimonial-item.box-style .testimonial-author {
  font-weight: bold;
}
.granite-testimonial-item.box-style .testimonial-author-image {
  width: 65px;
  height: 65px;
  position: absolute;
  right: 34px;
  bottom: -75px;
}

.granite-testimonial-item.plain-style .testimonial-item {
  text-align: center;
  font-size: 18px;
}

.granite-testimonial-item.plain-style .testimonial-item-inner {
  padding: 25px 30px;
  position: relative;
}
.granite-testimonial-item.plain-style .testimonial-item-inner:after {
  content: '';
  height: 0px;
  width: 0px;
  border: 12px solid transparent;
  position: absolute;
  right: 0px;
  bottom: -24px;
}
.granite-testimonial-item.plain-style .testimonial-content {
  font-style: italic;
}
.granite-testimonial-item.plain-style .testimonial-info {
  margin-top: 15px;
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0.5px;
}
.granite-testimonial-item.plain-style .testimonial-author {
  font-weight: bold;
}
.granite-testimonial-item.plain-style .testimonial-author:before {
  content: '- ';
  font-weight: bold;
  display: inline;
}
.granite-testimonial-item.plain-style .testimonial-position {
  font-size: 16px;
  font-style: italic;
}
.granite-testimonial-item.plain-style.large .testimonial-item {
  font-size: 18px;
}

.granite-testimonial-item.plain-style.granite-left .testimonial-item {
  text-align: left;
}
.granite-testimonial-item.plain-style.granite-left .testimonial-item:before {
  content: '“';
  font-size: 150px;
  line-height: 1;
  float: left;
  margin-right: 22px;
}
.granite-testimonial-item.plain-style.granite-left .testimonial-info {
  margin-top: 25px;
}
.granite-testimonial-item.plain-style.granite-left .testimonial-content-wrapper {
  overflow: hidden;
}
.granite-testimonial-item.plain-style.granite-left .testimonial-author:before {
  display: none;
}
.granite-testimonial-item.plain-style.granite-left .testimonial-author-image {
  width: 60px;
  height: 60px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  overflow: hidden;
}

/*--- block quote ---*/
blockquote {
  background-repeat: no-repeat;
  background-position: 20px 24px;
  font-size: 18px;
  font-style: italic;
  background-image: url('/assets/images/dark/quote-small.png');
  background-size: 24px 20px;
  padding: 15px 20px 17px 68px;
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-style: solid;
  margin-bottom: 20px;
}
blockquote.granite-align-left {
  width: 33%;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}
blockquote.granite-align-right {
  width: 33%;
  float: right;
  margin-right: 20px;
  margin-bottom: 20px;
}

/*--- dropcap ---*/
div.granite-dropcap {
  margin-top: 2px;
  margin-bottom: 0px;
  margin-right: 7px;
  margin-left: 2px;
  float: left;
  font-size: 25px;
  font-size: 29px;
  width: 32px;
  height: 32px;
  line-height: 30px;
  text-align: center;
  float: left;
}
div.granite-dropcap.granite-type-circle {
  -moz-border-radius: 16px;
  -webkit-border-radius: 16px;
  border-radius: 16px;
  font-size: 18px;
  margin-right: 10px;
}

/*--- styled box ---*/
.granite-styled-box-head-wrapper {
  overflow: hidden;
}
.granite-styled-box-head {
  overflow: hidden;
  height: 40px;
}
.granite-styled-box-corner {
  float: right;
  height: 0px;
  width: 0px;
  border-style: solid;
  border-width: 0px 40px 40px 0px;
  border-right-color: transparent;
}

.granite-styled-box-body {
  padding: 45px 40px 25px;
}
.granite-styled-box-body.with-head {
  padding-top: 5px;
}
.granite-styled-box-body .granite-styled-box-head-wrapper {
  overflow: hidden;
}

/*--- code item ---*/
pre {
  overflow: auto;
  background: #f3f3f3;
  margin-bottom: 20px;
  padding: 10px 22px 5px;
}

.granite-code-item {
  padding: 7px 15px;
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
}
.granite-code-item .granite-code-title {
  font-weight: bold;
  cursor: pointer;
}
.granite-code-item .granite-code-title i {
  font-size: 9px;
  margin-right: 7px;
}
.granite-code-item .granite-code-content {
  padding: 8px 14px;
  display: none;
  line-height: 2;
}
.granite-code-item.active .granite-code-content {
  display: block;
  line-height: 2;
}

/*--- process ---*/
.granite-process-wrapper .granite-process-tab {
  padding-bottom: 10px;
  margin-bottom: 20px;
  position: relative;
}
.granite-process-wrapper .granite-process-tab .granite-process-icon {
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 22px;
  padding: 30px;
  overflow: hidden;
  border-width: 3px;
  border-style: solid;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.granite-process-wrapper .granite-process-tab .granite-process-icon i {
  margin-right: 0px;
}
.granite-process-wrapper .granite-process-tab .granite-process-tab-content {
  overflow: hidden;
}
.granite-process-wrapper .granite-process-tab .granite-process-title {
  font-size: 21px;
  margin-bottom: 17px;
  font-weight: normal;
}
.granite-process-wrapper .granite-process-tab .process-line {
  position: absolute;
}
.granite-process-wrapper .granite-process-tab .process-line .process-line-divider {
  position: absolute;
}

.granite-process-wrapper.granite-vertical-process .granite-process-tab .granite-process-icon {
  float: left;
  margin-right: 30px;
  text-align: center;
}
.granite-process-wrapper.granite-vertical-process .granite-process-tab .process-line {
  top: 110px;
  bottom: 0px;
  left: 35px;
  width: 20px;
}
.granite-process-wrapper.granite-vertical-process .granite-process-tab .process-line .process-line-divider {
  left: 50%;
  margin-left: -1px;
  bottom: 5px;
  top: 0px;
  border-left-width: 1px;
  border-left-style: dashed;
}
.granite-process-wrapper.granite-vertical-process .granite-process-tab .process-line .fa-chevron-down,
.granite-process-wrapper.granite-vertical-process .granite-process-tab .process-line .icon-chevron-down {
  position: absolute;
  bottom: 0px;
  margin: 0px;
  font-size: 20px;
}

.granite-process-wrapper.granite-horizontal-process {
  text-align: center;
}
.granite-process-wrapper.granite-horizontal-process .granite-process-tab .granite-process-icon {
  margin: 0px auto 30px;
}
.granite-process-wrapper.granite-horizontal-process .granite-process-tab .process-line {
  top: 35px;
  left: 50%;
  right: -50%;
  height: 20px;
}
.granite-process-wrapper.granite-horizontal-process .granite-process-tab .process-line .process-line-divider {
  left: 75px;
  right: 55px;
  top: 50%;
  margin-top: -1px;
  border-top-width: 1px;
  border-top-style: dashed;
}
.granite-process-wrapper.granite-horizontal-process .granite-process-tab .process-line .fa-chevron-right,
.granite-process-wrapper.granite-horizontal-process .granite-process-tab .process-line .icon-chevron-right {
  position: absolute;
  right: 50px;
  margin: 0px;
  font-size: 20px;
}

/*----------------------------------------------------------------------*/
/*---------------               MEDIA ITEM            ------------------*/
/*----------------------------------------------------------------------*/

/*--- banner ---*/
.granite-banner-images ul.slides li {
  margin: 0px 15px;
}
.granite-banner-images ul.slides li:first-child {
  margin-left: 0px;
}
.granite-banner-description-item .granite-banner-title {
  font-size: 23px;
  margin-bottom: 15px;
  font-weight: normal;
}
.granite-banner-description-item .granite-banner-description {
  margin-bottom: 25px;
}
.granite-banner-description-item .nav-container {
  overflow: hidden;
}
.granite-banner-description-item .nav-container.style-1 .flex-direction-nav {
  margin-left: 0px;
}
.granite-banner-description-item .nav-container.style-1 .flex-control-nav {
  display: none;
}
.granite-banner-images ul.slides li img {
  width: auto;
  display: inline-block;
}
.granite-banner-images ul.slides li {
  text-align: center;
}

.granite-banner-item-wrapper .flex-direction-nav {
  line-height: 1;
}
.granite-banner-item-wrapper .flex-direction-nav a {
  padding-top: 18px;
  padding-bottom: 18px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.granite-banner-item-wrapper .flex-direction-nav .flex-prev {
  background: transparent;
  left: -60px;
  font-size: 30px;
}
.granite-banner-item-wrapper .flex-direction-nav .flex-next {
  background: transparent;
  right: -60px;
  font-size: 30px;
}
.granite-banner-item-wrapper:hover .flex-direction-nav .flex-prev,
.granite-banner-item-wrapper:hover .flex-direction-nav .flex-next {
  opacity: 1;
  filter: alpha(opacity=100);
}

/*--- gallery ---*/
.granite-gallery-item.granite-item {
  margin: 0px 10px;
}
.granite-gallery-item.granite-item .gallery-item {
  margin: 0px 5px 10px;
}
.granite-gallery-item.granite-item .gallery-item img {
  display: block;
  margin: 0px auto;
  width: 100%;
}
.granite-gallery-item.granite-item .gallery-caption {
  display: block;
  text-align: center;
  padding: 7px 0px 6px;
}

.granite-gallery-item.granite-item.granite-gallery-thumbnail .gallery-item {
  padding: 5px;
  cursor: pointer;
}
.granite-gallery-item.granite-item .granite-gallery-thumbnail-container {
  margin: 0px 5px 10px;
  position: relative;
  overflow: hidden;
}
.granite-gallery-item.granite-item .granite-gallery-thumbnail-container .granite-gallery-thumbnail {
  display: none;
}
.granite-gallery-item.granite-item .granite-gallery-thumbnail-container .granite-gallery-thumbnail:first-child {
  display: block;
}
.granite-gallery-item.granite-item .granite-gallery-thumbnail-container img {
  margin: 0px auto;
  display: block;
}
.granite-gallery-item.granite-item .granite-gallery-thumbnail-container .gallery-caption-wrapper {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  text-align: center;
}
.granite-gallery-item.granite-item .granite-gallery-thumbnail-container .gallery-caption {
  padding: 10px 15px;
  opacity: 0.8;
  filter: alpha(opacity=80);
  display: inline-block;
  max-width: 60%;
}
.granite-banner-images.granite-item .flex-direction-nav i {
  margin-right: 0px;
  font-size: 24px;
}

/*--- slider caption / stack image ---*/
.granite-caption-wrapper {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 90;
}
.granite-caption-wrapper .granite-caption-inner {
  margin: 0px auto;
}
.granite-caption-wrapper.position-center {
  text-align: center;
}
.granite-caption-wrapper.position-left .granite-caption {
  float: left;
  text-align: left;
}
.granite-caption-wrapper.position-right .granite-caption {
  float: right;
  text-align: left;
}
.granite-caption-wrapper.position-center .granite-caption {
  display: inline-block;
}
.granite-caption-wrapper .granite-caption {
  max-width: 40%;
  padding: 25px 28px;
}
.granite-caption-wrapper .granite-caption .granite-caption-title {
  font-size: 24px;
  line-height: 1;
  margin-bottom: 10px;
}
.granite-full-size-wrapper .granite-caption-wrapper.position-post-right {
  max-width: 350px;
}
.granite-caption-wrapper .granite-caption {
  opacity: 0.77;
  filter: alpha(opacity=77);
}

.granite-caption-wrapper.post-slider .granite-caption {
  max-width: none;
  background: transparent;
}
.granite-caption-wrapper.post-slider .granite-caption .blog-info {
  float: left;
  margin-right: 10px;
  margin-bottom: 13px;
}
.granite-post-slider-item.style-post-bottom .granite-caption .blog-info {
  display: none;
}
.granite-caption-wrapper.position-post-left {
  right: auto;
  top: 0px;
  max-width: 40%;
}
.granite-caption-wrapper.position-post-right {
  left: auto;
  top: 0px;
  max-width: 40%;
}
.granite-post-slider-item.style-post-left .granite-caption,
.granite-post-slider-item.style-post-right .granite-caption {
  padding: 30px 25px;
}
.granite-post-slider-item.style-post-left .flex-direction-nav .flex-prev {
  left: 40%;
}
.granite-post-slider-item.style-post-right .flex-direction-nav .flex-next {
  right: 40%;
}
.granite-caption-wrapper.post-slider,
.granite-post-slider-item.style-post-left .flex-prev,
.granite-post-slider-item.style-post-left .flex-next,
.granite-post-slider-item.style-post-right .flex-prev,
.granite-post-slider-item.style-post-right .flex-next {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.granite-post-slider-item {
  padding-bottom: 25px;
}
.granite-post-slider-item.style-no-excerpt .granite-caption-wrapper .granite-caption {
  position: relative;
  margin: 0px;
  display: inline-block;
}
.granite-post-slider-item.style-no-excerpt .granite-caption-wrapper .granite-caption-inner {
  position: relative;
}
.granite-post-slider-item.style-no-excerpt .granite-caption-wrapper .granite-caption-date {
  position: absolute;
  top: -33px;
  left: 0px;
  padding: 5px 12px;
}
.granite-post-slider-item.style-no-excerpt .granite-caption-wrapper .granite-caption-title {
  margin-bottom: 0px;
}
.granite-post-slider-item.style-no-excerpt .granite-caption-wrapper .granite-title-link {
  font-size: 18px;
  position: absolute;
  right: -26px;
  top: 0px;
  height: 30px;
  padding: 22px 10px;
}
.granite-post-slider-item.style-no-excerpt .granite-caption-wrapper .granite-title-link i {
  margin-right: 0px;
}

.granite-stack-image {
  position: relative;
  margin-bottom: 40px;
}
.granite-stack-image img {
  display: block;
}

/* -- Layer slider style modification --*/
body .ls-v5 .ls-bottom-slidebuttons a {
  width: 18px;
}
body .ls-v5 .ls-bottom-slidebuttons,
body .ls-v5 .ls-nav-start,
body .ls-v5 .ls-nav-stop,
body .ls-v5 .ls-nav-sides {
  top: -45px;
}
body .ls-v5 .ls-nav-prev {
  left: 20px;
}
body .ls-v5 .ls-nav-next {
  right: 20px;
}

/* -- Master slider style modification --*/


/*--- price item ---*/
.granite-price-item {
  text-align: center;
}
.granite-price-item .price-item-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 7px;
}
.granite-price-item .price-item-image {
  margin-bottom: 18px;
}

/*--- list menu ---*/
.granite-list-menu {
  padding: 13px 22px;
}
.granite-list-menu .granite-menu-item-content {
  position: relative;
  min-height: 38px;
}
.granite-list-menu .granite-menu-title {
  display: inline-block;
  font-size: 17px;
  letter-spacing: 0px;
  margin-bottom: 2px;
  font-weight: 400;
}
.granite-list-menu .granite-menu-ingredients-caption {
  font-size: 14px;
  margin-bottom: 2px;
}
.granite-list-menu .granite-menu-price {
  font-size: 20px;
  position: absolute;
  right: 0px;
  top: 0px;
  line-height: 1;
  font-weight: 600;
}
.granite-list-menu .granite-list-menu-gimmick {
  border-top: 1px;
  border-style: dashed;
  position: absolute;
  top: 10px;
}
.granite-list-menu .granite-menu-icon {
  margin-right: 12px;
}

/*--- price table ---*/
.granite-price-inner-item {
  margin: 0px 3px;
  border-style: solid;
  border-width: 1px;
  -moz-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
}
.granite-price-item .price-title-wrapper {
  padding-bottom: 5px;
  text-align: center;
}
.granite-price-item .price-title {
  font-size: 19px;
  padding: 6px 0px;
  margin: 0px;
}
.granite-price-item .price-tag {
  font-size: 22px;
  line-height: 1;
  padding: 25px 0px;
  font-weight: bold;
  background-image: url('/assets/images/light/price-tag-shadow.png');
  background-position: 0px 0px;
  background-repeat: repeat-x;
}
.granite-price-item .price-content {
  text-align: center;
  font-size: 13px;
  line-height: 1.5;
  overflow: hidden;
  padding-bottom: 5px;
}
.granite-price-item .price-content ul {
  list-style: none;
  margin-left: 0px;
}
.granite-price-item .price-content ul li {
  padding: 12px 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.granite-price-item .price-button {
  text-align: center;
}
.granite-price-item .price-button .granite-button {
  margin: 0px 0px 25px;
}

.granite-price-item .best-price {
  margin-top: -25px;
}
.granite-price-item .best-price .price-title {
  font-size: 22px;
}
.granite-price-item .best-price .price-tag {
  padding: 34px 20px;
  font-size: 30px;
}
.granite-price-item .best-price .price-button {
  padding-bottom: 15px;
}

/*----------------------------------------------------------------------*/
/*---------------            POST TYPE ITEM           ------------------*/
/*----------------------------------------------------------------------*/

/*--- pagination ---*/
.granite-pagination {
  margin: 0px 20px;
  text-align: right;
  margin-bottom: 60px;
}
.granite-pagination a {
  cursor: pointer;
}
.granite-pagination .page-numbers.current {
  cursor: default;
}
.granite-pagination .page-numbers {
  display: inline-block;
  padding: 7px 12px;
  margin-right: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

/*--- social share ---*/
.granite-social-share {
  margin-bottom: 40px;
  margin-top: 25px;
  line-height: 1;
  background: transparent !important;
}
.granite-social-share .social-share-title {
  font-size: 15px;
  line-height: 23px;
  font-weight: bold;
  float: left;
  margin-right: 20px;
  margin-bottom: 0px;
  margin-top: 2px;
}
.granite-social-share a {
  display: inline-block;
  width: 29px;
  margin-right: 7px;
  opacity: 0.3;
  filter: alpha(opacity=30);
  background: transparent !important;
}
.granite-social-share a:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.granite-social-share a img {
  display: block;
}

/*--- post author ---*/
.granite-post-author {
  margin-bottom: 45px;
}
.granite-post-author .post-author {
  font-size: 17px;
  margin-bottom: 10px;
  font-weight: 700;
}
.granite-post-author .post-author-title {
  font-size: 17px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 15px;
  padding-top: 10px;
  padding-bottom: 15px;
  border-bottom-width: 0px;
  border-bottom-style: solid;
  letter-spacing: 1px;
}
.granite-post-author .post-author-avartar {
  float: left;
  margin-right: 20px;
}
.granite-post-author .post-author-content {
  overflow: hidden;
}

/*--- comment ---*/
.granite-comments-area {
  margin-bottom: 60px;
}
.granite-comments-area .comments-title {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-top: 30px;
  padding-bottom: 8px;
  border-bottom-width: 0px;
  border-bottom-style: solid;
  letter-spacing: 2px;
}
.granite-comments-area ol.commentlist {
  list-style: none;
  margin-left: 0px;
}
.granite-comments-area ol.commentlist .comment-article {
  margin-bottom: 30px;
  padding-bottom: 5px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.granite-comments-area ol.commentlist ol.children {
  list-style: none;
  margin-left: 40px;
}
.granite-comments-area .comment-avatar {
  float: left;
  overflow: hidden;
  margin-right: 20px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.granite-comments-area .comment-avatar img {
  display: block;
}
.granite-comments-area .comment-body {
  overflow: hidden;
  position: relative;
}
.granite-comments-area .comment-author {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 4px;
}
.granite-comments-area .comment-reply {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 15px;
}
.granite-comments-area .comment-reply i {
  margin-right: 12px;
}
.granite-comments-area .comment-time {
  line-height: 20px;
  margin-bottom: 5px;
}
.granite-comments-area .comment-time i {
  font-size: 16px;
  line-height: 20px;
  float: left;
  margin-right: 6px;
}
.granite-comments-area .comment-time time {
  font-style: italic;
}
.granite-comments-area .comment-content {
  margin-top: 15px;
}

.comment-respond .comment-form {
  width: 101%;
}
.comment-respond .comment-reply-title {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
  padding-top: 10px;
  padding-bottom: 8px;
  border-bottom-width: 0px;
  border-bottom-style: solid;
  letter-spacing: 2px;
}
.comment-respond .comment-form-head > input {
  float: left;
  width: 28.5%;
  margin: 0 0 0 1.5%;
  padding: 11px 1.5%;
  border: 0px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.comment-respond .comment-form-head > input:first-child {
  width: 30%;
  margin-left: 0%;
}
.comment-respond .comment-form-comment > textarea {
  margin: 9px 0 0;
  padding: 11px 2%;
  width: 95%;
  border: 0px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
}
.comment-respond .comment-form-comment > textarea:focus {
  outline: none;
}
.comment-respond .form-submit > input[type='submit'] {
  margin-bottom: 0px;
  margin-top: 20px;
  padding: 15px 20px;
  font-size: 11px;
}

p.comment-form-cookies-consent {
  margin-top: 12px;
}
.comment-form-comment {
  margin-bottom: 13px;
}

/*--- blog ---*/
.granite-blog-thumbnail {
  position: relative;
  text-align: center;
}
.granite-blog-thumbnail .icon-bullhorn {
  margin-right: 9px;
}
.granite-blog-thumbnail .granite-sticky-banner {
  position: absolute;
  bottom: 0px;
  font-size: 12px;
  font-weight: bold;
  padding: 7px 15px;
  text-transform: uppercase;
  right: 0;
}

.granite-blog-thumbnail {
  background: #000000;
}
.granite-blog-thumbnail.granite-audio {
  background: transparent;
}
.format-gallery .granite-blog-thumbnail {
  background: transparent;
}
.granite-blog-thumbnail > a:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.granite-blog-carousel-item.granite-item .granite-blog-grid:first-child {
  margin-left: 0px;
}
.granite-item.granite-blog-grid {
  margin-bottom: 30px;
}
.granite-blog-grid .granite-blog-thumbnail {
  margin-bottom: 22px;
}
.granite-blog-grid .granite-blog-title {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 4px;
  letter-spacing: 1px;
}
.granite-blog-grid .granite-blog-content {
  margin-bottom: 0px;
}
.granite-blog-grid .granite-blog-info {
  margin-bottom: 15px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.8px;
}
.granite-blog-grid .granite-blog-info .blog-info {
  display: inline;
  margin-left: 18px;
}
.granite-blog-grid .granite-blog-info .blog-info i {
  font-size: 15px;
  margin-right: 10px;
}
.granite-blog-grid .granite-blog-info .blog-info:first-child {
  margin-left: 0px;
}
.granite-blog-grid .excerpt-read-more {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 20px;
}

.granite-blog-widget.granite-item {
  margin-bottom: 25px;
}
.granite-blog-widget .granite-blog-thumbnail {
  width: 70px;
  float: left;
  margin-right: 19px;
}
.granite-blog-widget .granite-blog-thumbnail .granite-sticky-banner {
  display: none;
}
.granite-blog-widget .granite-blog-date-wrapper {
  float: left;
  width: 45px;
  margin-right: 19px;
  text-align: center;
}
.granite-blog-widget .granite-blog-day {
  font-size: 33px;
  font-weight: 800;
  line-height: 1;
  margin-bottom: 4px;
}
.granite-blog-widget .granite-blog-month {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.granite-blog-widget .granite-blog-title {
  font-size: 16px;
  margin-bottom: 9px;
  font-weight: 700;
}
.granite-blog-widget .granite-blog-info {
  font-size: 13px;
  font-weight: bold;
}
.granite-blog-widget .granite-blog-info .blog-info {
  display: inline;
  margin-left: 17px;
}
.granite-blog-widget .granite-blog-info .blog-comment .granite-tail {
  display: none;
}
.granite-blog-widget .granite-blog-info .blog-info i {
  font-size: 15px;
  margin-right: 8px;
}
.granite-blog-widget .granite-blog-info .blog-info:first-child {
  margin-left: 0px;
}
.granite-blog-widget .post-header {
  overflow: hidden;
}

.granite-item.granite-blog-medium {
  margin-bottom: 50px;
}
.granite-blog-medium .granite-blog-thumbnail {
  margin-right: 25px;
  float: left;
  width: 35%;
}
.granite-blog-medium .granite-blog-date-wrapper {
  float: left;
  width: 45px;
  margin-right: 20px;
  text-align: center;
}
.granite-blog-medium .granite-blog-day {
  font-size: 35px;
  font-weight: bold;
  line-height: 1;
}
.granite-blog-medium .granite-blog-month {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.granite-blog-medium .granite-blog-content-wrapper {
  overflow: hidden;
}
.granite-blog-medium .granite-blog-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 7px;
}
.granite-blog-medium .granite-blog-info {
  margin-bottom: 16px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.8px;
}
.granite-blog-medium .granite-blog-info .blog-info {
  display: inline;
  margin-left: 22px;
}
.granite-blog-medium .granite-blog-info .blog-comment .granite-tail {
  display: none;
}
.granite-blog-medium .granite-blog-info .blog-info i {
  font-size: 15px;
  margin-right: 8px;
}
.granite-blog-medium .granite-blog-info .blog-info:first-child {
  margin-left: 0px;
}
.granite-blog-medium .granite-blog-content {
  margin-bottom: 22px;
}
.granite-blog-medium .excerpt-read-more {
  display: none;
}

.granite-item.granite-blog-full {
  margin-bottom: 45px;
}
.blog-item-holder .granite-blog-full .granite-blog-content {
  margin-bottom: 0px;
}
.granite-blog-full {
  border-bottom-width: 0px;
  border-bottom-style: solid;
}
.granite-blog-full:last-child {
  border-bottom-width: 0px;
  padding-bottom: 0px;
}
.granite-blog-full .granite-blog-date-wrapper {
  float: left;
  width: 50px;
  margin-right: 20px;
  text-align: center;
}
.granite-blog-full .granite-blog-day {
  font-size: 41px;
  font-weight: bold;
  line-height: 1;
}
.granite-blog-full .granite-blog-month {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.granite-blog-full .excerpt-read-more {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 20px;
}
.granite-blog-full .blog-content-wrapper {
  overflow: hidden;
}
.granite-blog-full .granite-blog-title {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.3px;
  margin-bottom: 4px;
}
.granite-blog-full .granite-blog-thumbnail {
  text-align: center;
  margin-bottom: 30px;
}
.granite-blog-full .granite-blog-info {
  margin-bottom: 15px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.8px;
}
.granite-blog-full .granite-blog-info .blog-info {
  display: inline;
  margin-left: 20px;
}
.granite-blog-full .granite-blog-info .blog-comment .granite-tail {
  display: none;
}
.granite-blog-full .granite-blog-info .blog-info i {
  font-size: 15px;
  margin-right: 10px;
}
.granite-blog-full .granite-blog-info .blog-info:first-child {
  margin-left: 0px;
}
.granite-blog-full .granite-single-blog-tag {
  margin-bottom: 25px;
}
.granite-blog-full .granite-single-blog-tag i {
  font-size: 16px;
  line-height: 16px;
  padding-top: 6px;
  margin-right: 20px;
  float: left;
}
.granite-blog-full .granite-single-blog-tag a {
  line-height: 12px;
  padding: 11px 16px;
  display: inline-block;
  margin-right: 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
}
.granite-blog-full .granite-single-blog-tag .sep {
  display: none;
}

body.single .granite-blog-full {
  border-bottom: 0px;
}
.granite-blog-thumbnail .flex-control-nav {
  display: none;
}
.granite-blog-thumbnail .flexslider .flex-prev,
.granite-blog-thumbnail .flexslider .flex-next {
  opacity: 0.7;
}

.granite-single-nav > div i {
  font-size: 18px;
  line-height: 20px;
  padding: 5px 12px;
  background: transparent !important;
}
.single-post .granite-single-nav {
  margin-bottom: 60px;
  width: 100%;
}
.single-post .granite-single-nav > div.previous-nav {
  padding-right: 10px;
  float: left;
}
.single-post .granite-single-nav > div.previous-nav i {
  margin-right: 3px;
}
.single-post .granite-single-nav > div.next-nav {
  padding-left: 10px;
  float: right;
  text-align: right;
}
.single-post .granite-single-nav > div.next-nav i {
  margin-left: 3px;
}
.single-post .granite-single-nav > div.next-nav,
.single-post .granite-single-nav > div.previous-nav {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 2px;
}

.granite-outer-nav.granite-nav-container i {
  font-size: 30px;
  position: absolute;
  top: 50%;
  margin-top: -4px;
}
.granite-outer-nav.granite-nav-container span {
  cursor: pointer;
  transition: background-color 200ms;
  -moz-transition: background-color 200ms;
  -o-transition: background-color 200ms;
  -webkit-transition: background-color 200ms;
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}
.granite-outer-nav.granite-nav-container span.granite-flex-next {
  display: block;
  position: absolute;
  padding: 0px 15px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 13px;
}
.granite-outer-nav.granite-nav-container span.granite-flex-next i {
  margin-left: 5px;
}
.granite-outer-nav.granite-nav-container span.granite-flex-prev {
  display: block;
  position: absolute;
  padding: 0px 15px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: 13px;
}
.granite-outer-nav.granite-nav-container span.granite-flex-prev i {
  margin-right: 5px;
}

/*--- single portfolio lightbox ---*/
.granite-single-lightbox {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 199;
  overflow-y: auto;
}
.granite-single-lightbox-overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: #111;
  padding: 5px;
  opacity: 0.8;
  filter: alpha(opacity=80);
  background-image: url('/assets/images/port-loader.gif');
  background-position: center;
  background-repeat: no-repeat;
}
.granite-single-lightbox-wrapper {
  margin-top: 80px;
  margin-bottom: 80px;
}
.granite-single-lightbox-close .granite-exit {
  width: 25px;
  height: 25px;
  padding: 25px;
  float: right;
  cursor: pointer;
  background: url('/assets/images/single-port-close.png') center no-repeat #000;
}
.granite-single-lightbox-container {
  padding: 40px;
}
.granite-single-lightbox-title {
  margin: 20px 20px 0px;
  font-size: 29px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

/*--- single portfolio ---*/
.granite-portfolio-info .portfolio-info .info-head {
  font-weight: bold;
  margin-right: 7px;
}
.granite-portfolio-info .granite-social-share {
  padding: 10px 0px 0px;
  background: transparent;
}
.granite-portfolio-info .social-share-title {
  display: none;
}

.single-portfolio .granite-single-nav i {
  margin-right: 2px;
}
.single-portfolio .granite-single-nav > div.previous-nav {
  float: left;
}
.single-portfolio .granite-single-nav > div.next-nav {
  float: left;
}
.single-portfolio .granite-single-nav {
  position: absolute;
  right: 0px;
  top: 5px;
}

.granite-related-portfolio .head {
  font-size: 24px;
  font-weight: bold;
  margin: 0px 20px 30px;
}
.granite-related-portfolio.portfolio-item-holder {
  margin-bottom: 25px;
}

.granite-portfolio-style1 .granite-portfolio-thumbnail {
  text-align: center;
  margin-bottom: 55px;
}
.granite-portfolio-style1 .granite-portfolio-thumbnail img {
  display: block;
  margin: 0px auto;
}
.granite-portfolio-style1 .granite-portfolio-description {
  width: 62%;
  float: left;
  margin-right: 3%;
  position: relative;
}
.granite-portfolio-style1 .granite-portfolio-content .head {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}
.granite-portfolio-style1 .granite-portfolio-info {
  width: 35%;
  float: right;
  position: relative;
}
.granite-portfolio-style1 .portfolio-info {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.granite-class-both-sidebar .granite-portfolio-style1 .granite-portfolio-description,
.granite-class-left-sidebar .granite-portfolio-style1 .granite-portfolio-description,
.granite-class-right-sidebar .granite-portfolio-style1 .granite-portfolio-description {
  float: none;
  margin-right: 0px;
  width: auto;
}
.granite-class-both-sidebar .granite-portfolio-style1 .granite-portfolio-info,
.granite-class-left-sidebar .granite-portfolio-style1 .granite-portfolio-info,
.granite-class-right-sidebar .granite-portfolio-style1 .granite-portfolio-info {
  width: auto;
  float: none;
  margin-bottom: 30px;
}

.granite-portfolio-style2.granite-item-start-content {
  margin-bottom: 35px;
}
.granite-portfolio-style2 .granite-portfolio-thumbnail {
  text-align: center;
  margin: 0px 30px 30px 0px;
  max-width: 60%;
  float: left;
}
.granite-portfolio-style2 .granite-portfolio-thumbnail.granite-video,
.granite-portfolio-style2 .granite-portfolio-thumbnail.granite-slider {
  width: 60%;
}
.granite-portfolio-style2 .granite-portfolio-thumbnail img {
  display: block;
  margin: 0px auto;
}
.granite-portfolio-style2 .granite-portfolio-content {
  overflow: hidden;
  position: relative;
}
.granite-portfolio-style2 .granite-portfolio-content .head {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 25px;
}
.granite-portfolio-style2 .granite-portfolio-info {
  margin-bottom: 45px;
}
.granite-portfolio-style2 .portfolio-info {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.granite-class-both-sidebar .granite-portfolio-style2 .granite-portfolio-thumbnail,
.granite-class-left-sidebar .granite-portfolio-style2 .granite-portfolio-thumbnail,
.granite-class-right-sidebar .granite-portfolio-style2 .granite-portfolio-thumbnail {
  max-width: 100%;
  float: none;
  margin-right: 0px;
  margin-left: 0px;
  width: auto !important;
}

/*--- portfolio ---*/
.portfolio-thumbnail {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.portfolio-thumbnail .flex-control-nav {
  display: none;
}
.portfolio-thumbnail .portfolio-overlay {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.portfolio-thumbnail .portfolio-overlay-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 24px;
  line-height: 1;
  margin-left: -12px;
  margin-top: -12px;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: none;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
}
.portfolio-thumbnail .portfolio-overlay-icon i {
  margin-right: 0px;
}
.portfolio-thumbnail .portfolio-overlay-content {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 20px;
  right: 20px;
  text-align: center;
  opacity: 0;
  filter: alpha(opacity=0);
}
.portfolio-thumbnail .flexslider .flex-prev,
.portfolio-thumbnail .flexslider .flex-next {
  opacity: 0.7;
}

.portfolio-classic-learn-more {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 20px;
  letter-spacing: 0.5px;
  display: none;
}
.classic-with-learn-more .portfolio-classic-learn-more {
  display: inline-block;
}
.granite-classic-portfolio.granite-item {
  margin-bottom: 35px;
  padding-right: 5px;
  margin-top: 5px;
}
.type-classic-portfolio-no-space .granite-classic-portfolio.granite-item {
  padding-right: 0;
}

.granite-classic-portfolio .portfolio-thumbnail {
  margin-bottom: 26px;
  overflow: hidden;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
.granite-classic-portfolio .portfolio-thumbnail.granite-video .portfolio-content-wrapper {
  display: none;
}
.granite-classic-portfolio .portfolio-thumbnail img {
  display: block;
  margin: 0px auto;
}
.granite-classic-portfolio .portfolio-title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 6px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.granite-classic-portfolio .portfolio-info {
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.granite-classic-portfolio .portfolio-info .info-head {
  display: none;
}
.granite-classic-portfolio .portfolio-excerpt {
  margin-top: 14px;
}
.granite-classic-portfolio .excerpt-read-more {
  display: none;
}
.type-classic-portfolio .granite-pagination {
  margin-top: 10px;
  position: relative;
  z-index: 10;
}
.type-classic-portfolio-no-space .portfolio-classic-content {
  padding: 0px 20px;
}
.type-classic-portfolio-no-space .granite-pagination {
  margin-top: -20px;
}

.granite-isotope[data-layout='masonry'] .granite-modern-portfolio.granite-item {
  margin: 0 4% 30px;
}
.granite-item-no-space .granite-isotope[data-layout='masonry'] .granite-modern-portfolio.granite-item {
  margin: 0;
}
.granite-item-no-space .granite-modern-portfolio.granite-item {
  margin-bottom: 0px;
}
.granite-modern-portfolio.granite-item {
  margin-bottom: 30px;
}
.granite-modern-portfolio .portfolio-thumbnail img {
  display: block;
  margin: 0px auto;
}
.granite-modern-portfolio .portfolio-thumbnail.granite-video .portfolio-content-wrapper {
  display: none;
}
.granite-modern-portfolio .portfolio-thumbnail .portfolio-icon {
  display: block;
  font-size: 20px;
  margin-bottom: 18px;
}
.granite-modern-portfolio .portfolio-thumbnail .portfolio-icon i {
  margin-right: 0px;
}
.granite-modern-portfolio .portfolio-title {
  font-size: 15px;
  margin-bottom: 4px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  display: inline-block;
  font-weight: bold;
}
.granite-modern-portfolio .granite-portfolio-info {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 2px;
}
.granite-modern-portfolio .granite-portfolio-info .info-head {
  display: none;
}
.type-modern-portfolio .granite-pagination {
  margin-top: 20px;
}
.type-modern-portfolio-no-space .granite-pagination {
  margin-top: 50px;
}
.granite-item-no-space .portfolio-thumbnail {
  margin-left: -1px;
}

.granite-portfolio-carousel-item .granite-portfolio-item:first-child {
  margin-left: 0px;
}
.portfolio-item-filter {
  margin: 0px 15px;
  text-align: center;
  padding-bottom: 50px;
}
.portfolio-item-filter a {
  margin-left: 36px;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1.7px;
}
.portfolio-item-filter a:first-child {
  margin-left: 0;
}
.granite-now-loading {
  height: 32px;
  padding: 40px 0px 80px;
  display: none;
  background: url('/assets/images/dark/now-loading.gif') center no-repeat;
}

/*--- list with icon ---*/
.list-with-icon {
  margin-bottom: 20px;
  padding-bottom: 0px;
  margin-top: 3px;
}
.list-with-icon .list-with-icon-image {
  width: 46px;
  font-size: 0;
}
.list-with-icon .list-with-icon-icon {
  width: 26px;
  height: 26px;
  font-size: 20px;
  padding: 10px;
  line-height: 26px;
  text-align: center;
  margin: 0px auto;
  border-width: 1px;
  border-style: solid;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.list-with-icon .list-with-icon-icon i {
  margin: 0px;
}
.list-with-icon .list-with-icon-title {
  overflow: hidden;
  font-size: 17px;
  font-weight: 800;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.list-with-icon .list-with-icon-content {
  overflow: hidden;
}

.list-with-icon.granite-left {
  text-align: left;
}
.list-with-icon.granite-left .list-with-icon-image,
.list-with-icon.granite-left .list-with-icon-icon {
  float: left;
  margin-right: 24px;
}
.list-with-icon.granite-right {
  text-align: right;
}
.list-with-icon.granite-right .list-with-icon-image,
.list-with-icon.granite-right .list-with-icon-icon {
  float: right;
  margin-left: 25px;
}

/*--- aside format ---*/
.format-aside .granite-blog-content {
  font-size: 20px;
  font-style: italic;
  padding: 30px 30px 30px;
  line-height: 1.5;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5 px;
}
.format-aside .granite-blog-content p:last-child {
  margin-bottom: 0px;
}

/*--- quote format ---*/
.format-quote .granite-top-quote blockquote {
  font-weight: bold;
  font-size: 25px;
  font-style: normal;
  line-height: 1.4;
  padding: 0px 0px 0px 55px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  background-size: 34px 29px;
  background-position: 0px 7px;
  background-image: url('/assets/images/dark/quote.png');
}
.format-quote .granite-top-quote blockquote,
.format-quote .granite-top-quote blockquote p {
  margin-bottom: 12px;
}
.format-quote .granite-quote-author {
  font-size: 14px;
  font-weight: bold;
  padding-left: 55px;
}
.post.format-quote .granite-blog-content {
  border-top-style: dashed;
  border-bottom-style: dashed;
  border-top-width: 1px;
  border-bottom-width: 1px;
  padding-top: 40px;
  padding-bottom: 20px;
}
.granite-blog-grid .post.format-quote .granite-blog-content {
  padding: 30px;
}

/*--- Link Format ---*/
.format-link .granite-blog-content {
  margin-bottom: 0px;
}
.granite-blog-full .post.format-link .granite-blog-content {
  margin-bottom: 0px;
}
.post.format-link {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: dashed;
  border-bottom-style: dashed;
  margin-bottom: 70px;
  padding: 40px 0px 40px;
}
.granite-blog-full .post.format-link .granite-blog-title {
  margin-bottom: 5px;
}
.granite-blog-grid .post.format-link {
  padding: 30px;
}

/*-- Blog --*/
.granite-blog-content {
  margin-bottom: 40px;
}

/*----------------------------------------------------------------------*/
/*---------------                 WIDGET              ------------------*/
/*----------------------------------------------------------------------*/

/*--- list ---*/
.widget_recent_entries ul,
.widget_recent_comments ul,
.widget_pages ul,
.widget_rss ul,
.widget_archive ul,
.widget_categories ul,
.widget_nav_menu ul,
.widget_meta ul {
  position: relative;
  list-style: none;
  margin-left: 0px;
}

.widget_recent_entries ul ul,
.widget_recent_comments ul ul,
.widget_pages ul ul,
.widget_rss ul ul,
.widget_archive ul ul,
.widget_categories ul ul,
.widget_nav_menu ul ul,
.widget_meta ul ul {
  margin-top: 15px;
  margin-bottom: 0px;
}

.widget_recent_entries ul ul li:last-child,
.widget_recent_comments ul ul li:last-child,
.widget_pages ul ul li:last-child,
.widget_rss ul ul li:last-child,
.widget_archive ul ul li:last-child,
.widget_categories ul ul li:last-child,
.widget_nav_menu ul ul li:last-child,
.widget_meta ul ul li:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom-width: 0px;
}

.widget_recent_entries ul li,
.widget_recent_comments ul li,
.widget_pages ul li,
.widget_rss ul li,
.widget_archive ul li,
.widget_categories ul li,
.widget_nav_menu ul li,
.widget_meta ul li {
  margin-bottom: 10px;
  padding-left: 25px;
  line-height: 20px;
  padding-bottom: 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.footer-wrapper .widget_recent_entries ul li,
.footer-wrapper .widget_recent_comments ul li,
.footer-wrapper .widget_pages ul li,
.footer-wrapper .widget_rss ul li,
.footer-wrapper .widget_archive ul li,
.footer-wrapper .widget_categories ul li,
.footer-wrapper .widget_nav_menu ul li,
.footer-wrapper .widget_meta ul li {
  border-bottom-width: 1px;
}

.widget_recent_entries ul li:before,
.widget_recent_comments ul li:before,
.widget_pages ul li:before,
.widget_rss ul li:before,
.widget_archive ul li:before,
.widget_categories ul li:before,
.widget_nav_menu ul li:before,
.widget_meta ul li:before {
  content: '\f105';
  font-family: FontAwesome;
  text-align: center;
  position: absolute;
  left: 0px;
  display: block;
  height: 20px;
  width: 20px;
  margin-top: -1px;
}

/*--- tag could ---*/
.tagcloud a {
  font-size: 11px !important;
  display: block;
  float: left;
  padding: 8px 13px;
  margin-right: 7px;
  margin-top: 7px;
  border: 0px solid;
  border-color: inherit;
  font-weight: bold;
  text-transform: uppercase;
}
.tagcloud a:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.tagcloud {
  display: inline-block;
}

/*--- search form ---*/
.gdl-search-form {
  padding-right: 22px;
  position: relative;
  padding-right: 32px;
}
.gdl-search-form input[type='text'] {
  letter-spacing: 1px;
  border-width: 1px;
  border-style: solid;
  width: 100%;
  padding: 15px 15px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  font-size: 13px;
}
.gdl-search-form input[type='submit'] {
  position: absolute;
  right: 15px;
  top: 50%;
  margin: -8px 0px 0px;
  width: 16px;
  height: 16px;
  padding: 0px;
  background-color: transparent !important;
  background-size: 16px 16px;
  background-image: url('/assets/images/dark/icon-search.png');
  border: none;
}

/*--- recent post widget ---*/
.recent-post-widget {
  margin-bottom: 15px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 15px;
}
.recent-post-widget .recent-post-widget-thumbnail {
  max-width: 55px;
  float: left;
  margin-right: 15px;
}
.recent-post-widget .recent-post-widget-thumbnail img {
  display: block;
}
.recent-post-widget .recent-post-widget-content {
  overflow: hidden;
  margin-top: 5px;
}
.recent-post-widget .recent-post-widget-title {
  font-size: 14px;
  margin-bottom: 6px;
  margin-top: 2px;
  font-weight: 600;
}
.recent-post-widget .recent-post-widget-info {
  line-height: 22px;
}
.recent-post-widget .recent-post-widget-info i {
  font-size: 15px;
  margin-right: 9px;
}

.top-rated-post-widget {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  overflow: hidden;
}
.top-rated-post-widget .top-rated-post-rating {
  color: #fff;
  background: #f5be3b;
}
.top-rated-post-widget .top-rated-post-rating {
  width: 26px;
  height: 20px;
  padding: 15px 12px;
  text-align: center;
  float: left;
  margin-right: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 13px;
  font-weight: 700;
  line-height: 19px;
}
.top-rated-post-widget .top-rated-post-title {
  overflow: hidden;
  margin-top: 10px;
}

/*--- recent comment widget ---*/
.granite-recent-comment-widget .recent-comment-widget-thumbnail {
  overflow: hidden;
  float: left;
  margin-right: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.granite-recent-comment-widget .recent-comment-widget-content {
  overflow: hidden;
}
.granite-recent-comment-widget .recent-commnet-widget {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.granite-recent-comment-widget .recent-comment-widget-title {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.granite-recent-comment-widget .recent-comment-widget-info {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
}

/*--- twitter widget ---*/
.granite-twitter-item-wrapper {
  font-size: 19px;
  text-align: center;
}
.granite-twitter-item-wrapper .fa-twitter {
  color: #5fc1ff;
  margin-right: 0px;
  font-size: 40px;
  line-height: 1;
  margin-bottom: 25px;
}
.granite-twitter-item-wrapper .granite-twitter-time {
  display: block;
  font-size: 14px;
  margin-top: 14px;
  font-style: italic;
}
.granite-twitter-item-wrapper .granite-twitter-time:before {
  content: '- ';
}

ul.granite-twitter-widget {
  list-style: none;
  margin-left: 0px;
}
ul.granite-twitter-widget li {
  margin-bottom: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
ul.granite-twitter-widget li:before {
  font-size: 19px;
  line-height: 24px;
  float: left;
  margin-right: 20px;
  margin-top: 2px;
  font-family: FontAwesome;
  content: '\f099';
}
ul.granite-twitter-widget li .granite-twitter {
  margin-bottom: 20px;
  overflow: hidden;
}

/*--- recent port 2 widget ---*/
.granite-recent-port2-widget .recent-port-widget-thumbnail {
  max-width: 70px;
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
}
.footer-wrapper .granite-recent-port2-widget .recent-port-widget-thumbnail {
  max-width: 70px;
}

/*--- flickr widget ---*/
.granite-flickr-widget .flickr_badge_image {
  max-width: 70px;
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
}
.footer-wrapper .granite-flickr-widget .flickr_badge_image {
  max-width: 57px;
}

/*--- post slider widget ---*/
.granite-post-slider-widget .flex-control-nav {
  display: none;
}
.granite-post-slider-widget .granite-caption-title {
  padding: 7px;
  font-size: 13px;
  text-align: center;
}
.granite-post-slider-widget .flex-direction-nav li a {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: -30px;
}

/*--- Calendar Widget ---*/
.widget_calendar caption {
  font-size: 14px;
  margin-bottom: 14px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}
.widget_calendar th {
  font-size: 11px;
  font-weight: bold;
}
.widget_calendar table,
.widget_calendar tr,
.widget_calendar td {
  padding: 10px 0px;
}
.widget_calendar table tfoot td {
  border-bottom: 0;
}

/*----------------------------------------------------------------------*/
/*---------------                PLUGINS              ------------------*/
/*----------------------------------------------------------------------*/

/*--- Isotope Filtering ---*/
.isotope-item {
  z-index: 2;
}
.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}
.isotope,
.isotope .isotope-item {
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -ms-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
}
.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width;
}
.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity;
}
.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s;
}

/*--- easy pie chart ---*/
.granite-chart {
  position: relative;
  text-align: center;
  display: inline-block;
}
.granite-chart canvas {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  max-height: none\9; /*- for ie 8 and below-*/
}
.granite-chart .chart-content-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  line-height: 1;
  z-index: 1;
}
.granite-chart .chart-content-wrapper .chart-content-inner {
  position: relative;
  left: -50%;
  margin-top: -25%;
}
.granite-chart .chart-content-wrapper .chart-content {
  font-size: 16px;
  margin-bottom: 8px;
  display: block;
}
.granite-chart .chart-content-wrapper .chart-percent-number {
  font-size: 24px;
  display: block;
}
.granite-chart .chart-content-wrapper .chart-content i {
  font-size: 25px;
  margin-top: -28px;
  margin-bottom: 10px;
  margin-right: 0;
  display: block;
}

/*--- easy mailchimp form ---*/
.granite-mailchimp-form .yks-require-description {
  display: none;
}
.granite-mailchimp-form .yks-mailchimpFormDivRowLabel,
.granite-mailchimp-form .yks-mailchimpFormDivRowField {
  display: block;
  margin-bottom: 3px;
  width: auto;
}
.granite-mailchimp-form .yks-mailchimpFormDivRow {
  margin-top: 9px;
}
.granite-mailchimp-form .yks-mailchimpFormDivRow:first-child {
  margin-top: 0px;
}
.granite-mailchimp-form input[type='text'],
.granite-mailchimp-form input[type='email'],
.granite-mailchimp-form input[type='password'] {
  width: 200px;
  padding: 12px;
  margin-bottom: 5px;
}
.granite-mailchimp-form p,
.granite-mailchimp-form input[type='submit'] {
  margin-bottom: 0px;
}
.granite-mailchimp-form .yks-status .yks-success {
  white-space: normal;
  text-align: center;
  margin: 0px auto;
  padding: 5px 12px;
}

/*--- wpml ---*/
.granite-wpml-language-selector img {
  margin-right: 12px;
  vertical-align: middle;
}

/*--- woocommerce cart ---*/
.granite-woocommerce-cart .sub-menu-item {
  padding-top: 12px;
  margin-top: 12px;
  border-top-width: 1px;
  border-top-style: solid;
}
.granite-woocommerce-cart .sub-menu-item:first-child {
  padding-top: 0px;
  border-top-width: 0px;
}
.granite-woocommerce-cart .sub-menu-item .head {
  font-weight: bold;
}
.granite-woocommerce-cart .granite-button {
  margin-bottom: 0px;
}
.granite-woocommerce-cart .granite-button:last-child {
  margin-right: 0px;
}
.granite-woocommerce-cart .sub-menu-item .granite-button {
  margin-top: 9px;
}

/*--- fitvids ---*/
.granite-fluid-video-wrapper {
  width: 100%;
  position: relative;
  padding: 0;
}
.granite-fluid-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*--- contact form 7 ---*/
.wpcf7-contact-half-left {
  float: left;
  width: 50%;
}
.wpcf7-contact-half-right {
  float: left;
  width: 50%;
}
.wpcf7 p {
  margin-bottom: 25px;
}
.wpcf7 input[type='text'],
.wpcf7 input[type='email'],
.wpcf7 input[type='password'] {
  height: 16px;
  padding: 19px 19px;
  width: 100%;
  font-size: 13px;
}
.wpcf7 textarea {
  height: 150px;
  padding: 19px 19px;
  width: 100%;
  font-size: 13px;
  display: block;
  line-height: 1.7;
}
.wpcf7 textarea.wpcf7-full {
  height: 420px;
}
.wpcf7 input[type='submit'] {
  padding: 20px;
  letter-spacing: 0;
  text-transform: none;
  width: 100%;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 800;
}
.wpcf7 span.wpcf7-form-control-wrap {
  display: block;
  padding-right: 38px;
  margin-top: 7px;
}
.wpcf7 .wpcf7-contact-half-left span.wpcf7-form-control-wrap {
  padding-right: 53px;
}
.wpcf7 .wpcf7-contact-half-right span.wpcf7-form-control-wrap {
  padding-left: 15px;
}
.wpcf7-contact-half-left input[type='text'],
.wpcf7-contact-half-left input[type='email'],
.wpcf7-contact-half-left input[type='password'] {
  padding: 22px 19px;
}
.wpcf7-contact-half-left textarea {
  padding: 22px 19px;
}
.wpcf7-contact-half-right textarea {
  padding: 22px;
}
.wpcf7 .wpcf7-contact-half-left p {
  margin-bottom: 30px;
}

.footer-wrapper .wpcf7 p {
  margin-bottom: 10px;
}
.footer-wrapper .wpcf7 input[type='text'],
.footer-wrapper .wpcf7 input[type='email'],
.footer-wrapper .wpcf7 input[type='password'],
.footer-wrapper .wpcf7 textarea {
  padding: 15px 18px;
  width: 100%;
}
.footer-wrapper .wpcf7 span.wpcf7-form-control-wrap {
  padding-right: 38px;
  margin-top: 5px;
}
.footer-wrapper .wpcf7 input[type='submit'] {
  padding: 10px 20px;
  letter-spacing: 0;
  text-transform: none;
}

.wpcf7 input:focus::input-placeholder {
  color: transparent;
}
.wpcf7 input:focus::-webkit-input-placeholder {
  color: transparent;
}
.wpcf7 input:focus::-moz-placeholder {
  color: transparent;
}
.wpcf7 input:focus:-moz-placeholder {
  color: transparent;
}
.wpcf7 input:focus:-ms-input-placeholder {
  color: transparent;
}
.wpcf7 textarea:focus::input-placeholder {
  color: transparent;
}
.wpcf7 textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
.wpcf7 textarea:focus::-moz-placeholder {
  color: transparent;
}
.wpcf7 textarea:focus:-moz-placeholder {
  color: transparent;
}
.wpcf7 textarea:focus:-ms-input-placeholder {
  color: transparent;
}

/*--- newsletter subscribe ---*/
.granite-subscribe-item {
  text-align: center;
}
.granite-subscribe-item .granite-newsletter-subscribe input {
  display: inline-block;
}
.granite-subscribe-item .granite-newsletter-subscribe input[type='email'] {
  padding: 20px 2%;
  height: 16px;
  width: 530px;
  margin-right: 10px;
  font-size: 15px;
  max-width: 96%;
}
.granite-subscribe-item .granite-newsletter-subscribe input[type='submit'] {
  margin: 1px;
  height: 56px;
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 800;
}

.newsletter-subscription table tr th,
.newsletter-subscription table tr td {
  padding: 0px 20px;
  border: 0px;
}
.newsletter-subscription table tr td.newsletter-td-submit {
  padding: 20px 0px 0px;
  border: 0px;
}
.newsletter-subscription table tr td.newsletter-td-submit input[type='submit'] {
  margin: 0px;
}
.newsletter-subscription table tr th {
  vertical-align: middle;
  padding: 0px 10px;
  font-size: 14px;
}
.newsletter-subscription table tr td input.newsletter-email {
  padding: 6px 10px;
  height: 32px;
  width: 100%;
}

/*--- background player ---*/
.granite-bg-player {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}
.granite-player-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: #000;
}

/*----------------------------------------------------------------------*/
/*---------------                RETINA               ------------------*/
/*----------------------------------------------------------------------*/
@media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  blockquote {
    background-image: url('/assets/images/dark/quote%402x.png');
    background-repeat: no-repeat;
  }
  .gdl-search-form input[type='submit'] {
    background-image: url('/assets/images/dark/icon-search%402x.png');
  }
  .page-not-found-search .gdl-search-form input[type='submit'] {
    background-image: url('/assets/images/light/icon-search%402x.png');
  }
}

.granite-top-woocommerce-button {
  color: #fff;
}
.granite-top-woocommerce-inner {
  background: #242424;
}
.granite-top-woocommerce,
.granite-top-woocommerce-inner > a {
  color: #fff;
}

/*-- Master Slider --*/
.master-slider-parent {
  padding-bottom: 0px !important;
}

/*--- Font Awesome ---*/
.icon-angle-left,
.icon-angle-right,
.icon-minus,
.icon-plus {
  line-height: 1;
}
.icon-angle-left:before {
  content: '\f104';
  font-family: fontAwesome;
  font-style: normal;
}
.icon-angle-right:before {
  content: '\f105';
  font-family: fontAwesome;
  font-style: normal;
}
.icon-minus:before {
  content: '\f068';
  font-family: fontAwesome;
  font-style: normal;
}
.icon-plus:before {
  content: '\f067';
  font-family: fontAwesome;
  font-style: normal;
}

/*-- icno shortcode --*/
span.granite-icon-inline-text {
  letter-spacing: 1.7px;
}
span.gdlr-icon-shortcode {
  display: inline-block;
}

/*--- payment form ---*/
.granite-payment-lightbox-overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #000;
  z-index: 9999;
  opacity: 0.75;
  filter: alpha(opacity=75);
  display: none;
}
.granite-payment-lightbox-container {
  position: fixed;
  top: 60px;
  right: 0px;
  bottom: 60px;
  left: 0px;
  z-index: 9999;
  width: 680px;
  max-height: 830px;
  margin: auto;
  overflow: auto;
  display: none;
}
.granite-payment-lightbox-inner {
  padding: 30px 35px 40px;
}
.granite-payment-lightbox-title {
  font-size: 22px;
  margin-bottom: 30px;
}
.granite-payment-lightbox-title .granite-head {
  margin-right: 10px;
}
.granite-payment-lightbox-title .granite-tail {
  font-weight: bold;
}
.granite-payment-amount {
  margin-bottom: 32px;
}
.granite-payment-amount-head {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 15px;
}
.granite-payment-price-preset {
  padding: 13px 20px;
  display: inline-block;
  cursor: pointer;
  font-size: 17px;
  font-weight: bold;
  margin-right: 9px;
  line-height: 17px;
}
input[type='text'].granite-payment-price-fill {
  display: inline-block;
  height: 43px;
  font-weight: bold;
  vertical-align: bottom;
  padding: 0px 17px;
}
.granite-payment-form input[type='submit'] {
  margin: 15px 0px 0px;
  width: 100%;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}
.granite-payment-form .granite-message {
  display: none;
  margin-top: 8px;
  padding: 8px 16px;
  font-weight: bold;
}
.granite-payment-form .granite-loading {
  display: none;
  margin-top: 8px;
}

.granite-paypal-attribute {
  font-size: 12px;
  margin-bottom: 32px;
}
.granite-paypal-attribute .granite-head {
  display: block;
  margin-bottom: 8px;
}
.granite-paypal-attribute .granite-subhead {
  margin-right: 10px;
}
.granite-paypal-attribute select.granite-recurring-option {
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
}
.granite-paypal-attribute .granite-recurring-time-wrapper {
  margin-top: 7px;
  display: none;
}

.granite-form-fields input[type='text'] {
  padding: 12px 20px;
  width: 100%;
}
.granite-form-fields textarea {
  padding: 12px 20px;
  width: 100%;
  height: 80px;
}
.granite-form-fields .granite-head {
  display: block;
  font-size: 14px;
  margin-bottom: 6px;
}
.granite-form-fields .columns-wrap {
  padding-right: 40px;
  margin-bottom: 15px;
}
.granite-form-fields .columns-wrap.granite-left {
  margin-right: 15px;
}
.granite-form-fields .columns-wrap.granite-right {
  margin-left: 15px;
}

.granite-payment-method img {
  padding: 2px;
  margin-right: 15px;
  cursor: pointer;
}
.granite-payment-method img.granite-active {
  border-width: 2px;
  border-style: solid;
  padding: 0px;
}

.granite-single-payment-form label {
  display: block;
  margin-bottom: 10px;
}
.granite-single-payment-form .granite-form-half-left {
  width: 50%;
}
.granite-single-payment-form input[type='text'] {
  width: 100%;
  padding: 15px 20px;
}
.granite-form-expiration input[type='text'] {
  width: 50px;
}
.granite-form-expiration .granite-separator {
  margin: 0px 10px;
}
.granite-single-payment-form .granite-form-instant-payment-loading {
  margin-bottom: 20px;
  display: none;
}
.granite-single-payment-form .granite-form-notice,
.granite-single-payment-form .granite-form-error {
  padding: 10px 15px;
  margin-bottom: 20px;
  display: none;
}

.granite-payment-form input[type='text'],
.granite-payment-form textarea {
  background-color: #ffffff;
}
.granite-payment-price-preset,
.granite-payment-price-preset:hover {
  color: #fff;
}
.granite-payment-price-preset,
.granite-payment-price-preset:hover {
  background-color: #c3c3c3;
}
.granite-payment-price-preset.granite-active {
  background-color: #ecb338;
}
.granite-payment-lightbox-inner {
  background-color: #f3f3f3;
}

.granite-single-payment-form .granite-form-notice,
.granite-payment-form .granite-message.granite-success {
  background-color: #99d15e;
  color: #fff;
}
.granite-single-payment-form .granite-form-error,
.granite-payment-form .granite-message.granite-failed {
  background-color: #d15e5e;
  color: #fff;
}

.filter ul {
  margin: 0 auto 70px;
  display: table;
}

.filter li {
  display: inline;
  list-style: none;
  margin: 0 20px;
}

.filter li:first-child {
  margin-left: 0;
}
.filter li:last-child {
  margin-right: 0;
}

.filter a {
  padding-bottom: 10px;
  text-transform: uppercase;
  color: #7287a2;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
}

.filter a.active {
  color: #000 !important;
}

.submit-button {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.8px;
  padding: 15px;
  width: 100%;
  display: block;
  -webkit-appearance: none;
  background-color: #4b9e55;
  border: 0;
  color: #fff;
  font-style: normal !important;
  cursor: pointer;
  font-style: normal;
}

.granite-image img {
  width: 100%;
}

html body {
  min-width: 300px;
}

#granite-responsive-navigation {
  display: none;
}

/*--- tablet ---*/
@media only screen and (max-width: 1100px) {
  .granite-button-overlay,
  .granite-woo-button-overlay {
    display: none;
  }
  .granite-donate-button {
    margin-left: 25px;
    width: auto;
  }
  .granite-top-woocommerce-button {
    margin-right: 0px;
    margin-left: 25px;
  }
  .granite-navigation-wrapper .granite-main-menu > li > a.sf-with-ul-pre,
  .granite-navigation-wrapper .granite-main-menu > li > a.sf-with-ul .granite-navigation-wrapper .granite-main-menu > li > a {
    padding-right: 25px;
  }
}
@media only screen and (max-width: 1050px) {
  body .granite-logo {
    float: none;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  body .granite-logo-inner {
    margin: 0px auto;
  }
  body .granite-navigation-wrapper {
    float: none;
    margin-top: 10px;
    line-height: 0;
    text-align: center;
  }
  body .granite-navigation {
    display: inline-block;
    line-height: 1.7;
    text-align: left;
  }
  body .granite-logo-right-text {
    float: none;
    margin-top: 10px;
    text-align: center;
  }
  body .granite-navigation-wrapper .granite-main-menu > li > a {
    padding-bottom: 25px;
  }
  body .granite-button-overlay {
    top: auto;
    height: 70px;
  }
  body .granite-fixed-header .granite-logo {
    display: none;
  }
}

@media only screen and (max-width: 959px) {
  body .container {
    max-width: 768px;
  }
  body .body-wrapper.granite-boxed-style {
    max-width: 808px;
  }

  .granite-hide-in-tablet {
    display: none;
  }
  .granite-hide-in-tablet-mobile {
    display: none;
  }

  .flex-direction-nav .flex-prev,
  .flex-direction-nav .flex-next {
    opacity: 1;
  }
  .granite-fixed-header {
    display: none;
  }

  .gdlr-icon-shortcode {
    margin-bottom: 10px;
  }

  .granite-blog-widget .granite-blog-date-wrapper {
    display: none;
  }
}

/*--- mobile landscape style ---*/
@media only screen and (max-width: 767px) {
  body .container {
    max-width: 420px;
  }
  body .body-wrapper.granite-boxed-style {
    max-width: 460px;
  }
  body .body-wrapper {
    overflow: hidden;
  }

  .wpcf7-contact-half-right,
  .wpcf7-contact-half-left {
    float: none;
    width: auto;
  }

  .one-ninth,
  .one-eighth,
  .one-seventh,
  .one-fifth,
  .two-fifth,
  .three-fifth,
  .four-fifth,
  .one,
  .two,
  .three,
  .four,
  .five,
  .six,
  .seven,
  .eight,
  .nine,
  .ten,
  .eleven,
  .twelve {
    width: 100%;
  }
  .granite-half-bg-wrapper .six.columns {
    left: auto;
    right: auto;
  }

  .granite-hide-in-tablet {
    display: block;
  }
  .granite-hide-in-mobile {
    display: none;
  }

  .granite-half-bg.granite-bg-solid {
    display: none;
  }
  .granite-service-half-background-item .granite-half-left,
  .granite-service-half-background-item .granite-half-right {
    float: none;
    width: auto;
    padding: 70px 0px;
    margin: 0px;
  }
  .granite-service-half-background-item .granite-half-left .granite-item-margin,
  .granite-service-half-background-item .granite-half-right .granite-item-margin {
    margin: 0px 15px;
  }
  .granite-service-half-background-item .granite-half-left .half-container,
  .granite-service-half-background-item .granite-half-right .half-container {
    max-width: 420px;
    margin: 0px auto;
  }

  /*--- header ---*/
  body .top-navigation-left {
    float: none;
    text-align: center;
    padding: 3px 0px;
  }
  body .top-navigation-right {
    float: none;
    text-align: center;
    padding: 3px 0px;
  }
  body .granite-logo {
    padding-right: 60px;
  }
  body .granite-logo-inner {
    margin-left: 0px;
  }

  #granite-responsive-navigation {
    display: block;
    height: 0px;
    position: static;
    float: none;
  }
  #granite-responsive-navigation .dl-trigger {
    position: fixed;
    left: 8px;
    top: 8px;
    margin-top: 0px;
  }
  .dl-menuwrapper > .dl-menu,
  .dl-menuwrapper > .dl-submenu {
    margin: 20px 0px 0px;
    width: 390px;
    text-align: left;
  }

  .granite-navigation-wrapper {
    display: none;
  }

  /*--- page item ---*/
  .granite-item-title-head .granite-nav-title {
    display: none;
  }

  .granite-item-title-wrapper.granite-left .granite-item-title,
  .granite-item-title-wrapper.granite-left .granite-item-title-caption,
  .granite-item-title-wrapper.granite-left-divider .granite-item-title,
  .granite-item-title-wrapper.granite-left-divider .granite-item-title-caption {
    display: block;
  }
  .granite-item-title-wrapper.granite-left .granite-item-title-link,
  .granite-item-title-wrapper.granite-left-divider .granite-item-title-link {
    float: none;
    display: inline-block;
  }

  .granite-title-left .about-us-title-wrapper {
    width: auto;
    float: none;
    text-align: center;
  }
  .granite-title-left .about-us-title-divider {
    margin-right: auto;
    margin-bottom: 26px;
  }

  .granite-list-menu {
    padding: 0px;
  }

  .granite-stunning-text-item {
    text-align: center;
  }
  .granite-stunning-text-item.type-normal.granite-button-on {
    padding-right: 0px;
  }
  .granite-stunning-text-item.type-normal .stunning-text-button {
    position: static;
    margin-top: 0px;
  }
  .granite-stunning-text-item.with-padding.granite-button-on {
    padding-right: 35px;
  }

  .granite-stunning-item.granite-item.granite-button-on.granite-stunning-left {
    padding-right: 0px;
  }
  .granite-stunning-item.granite-stunning-left .stunning-item-button {
    position: relative;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .granite-column-service-item.granite-type-2-bg {
    height: auto !important;
  }

  .granite-service-with-image-item .service-with-image-thumbnail {
    float: none;
    margin: 0px auto;
  }
  .granite-service-with-image-item .service-with-image-content-wrapper {
    text-align: center;
  }

  .granite-feature-media-item.granite-left .feature-media-thumbnail {
    float: none;
    width: auto;
    margin-right: 0px;
  }
  .granite-feature-media-item .feature-media-icon {
    display: none;
  }
  html .woocommerce ul.products li.product,
  html .woocommerce-page ul.products li.product {
    width: 99% !important;
  }

  .granite-subscribe-item .granite-newsletter-subscribe input[type='submit'] {
    margin-top: 20px;
  }

  .wpcf7 .wpcf7-contact-half-right span.wpcf7-form-control-wrap {
    padding-left: 0px;
    padding-right: 44px;
  }

  /*--- single page ---*/
  .granite-portfolio-style1 .granite-portfolio-description {
    float: none;
    margin-right: 0px;
    width: auto;
  }
  .granite-portfolio-style1 .granite-portfolio-info {
    width: auto;
    float: none;
    margin-bottom: 30px;
  }
  .granite-portfolio-style2 .granite-portfolio-thumbnail {
    max-width: 100%;
    float: none;
    margin-right: 0px;
    margin-left: 0px;
    width: auto !important;
  }

  .granite-blog-medium .granite-blog-thumbnail {
    margin-right: 0px;
    float: none;
    margin-bottom: 20px;
    width: auto;
  }

  /*--- slider ---*/
  .granite-caption-wrapper .granite-caption .granite-caption-text {
    display: none;
  }
  .granite-caption-wrapper .granite-caption .granite-caption-title {
    margin-bottom: 0px;
    font-size: 15px;
  }
  .granite-caption-wrapper .granite-caption {
    max-width: 100%;
    padding: 13px 12px;
  }

  .granite-item-title .granite-flex-prev {
    margin: 0px;
    position: absolute;
    left: 0px;
    top: 50%;
    margin-top: -20px;
  }
  .granite-item-title .granite-flex-next {
    margin: 0px;
    position: absolute;
    right: 0px;
    top: 50%;
    margin-top: -20px;
  }

  /*--- header style ---*/
  .granite-header-wrapper.header-style-5-wrapper {
    position: relative;
  }
  .header-style-5-title-wrapper.granite-page-title-wrapper {
    padding-top: 74px;
  }

  /* donation lightbox form */
  .granite-payment-lightbox-container {
    width: auto;
    left: 20px;
    right: 20px;
    top: 20px;
    bottom: 20px;
  }
  .granite-form-fields .columns-wrap.granite-right {
    margin-left: 0px;
  }
  .granite-form-fields .columns-wrap.granite-left {
    margin-right: 0px;
  }
  .granite-payment-price-preset {
    margin-bottom: 15px;
  }
}

/*--- mobile portrait style ---*/
@media only screen and (max-width: 419px) {
  body .container {
    max-width: 300px;
  }
  body .body-wrapper.granite-boxed-style {
    max-width: 340px;
  }

  .gdlr-service-half-background-item .gdlr-half-left .half-container,
  .gdlr-service-half-background-item .gdlr-half-right .half-container {
    max-width: 300px;
  }

  .dl-menuwrapper .dl-menu,
  .dl-menuwrapper > .dl-submenu {
    width: 270px;
  }
}
html.ltie9 body,
body {
  min-width: 1140px;
}
.container {
  max-width: 1140px;
}
.granite-caption-wrapper .granite-caption-inner {
  max-width: 1140px;
}
.body-wrapper.granite-boxed-style {
  max-width: 1220px;
  overflow: hidden;
}
.body-wrapper.granite-boxed-style .granite-header-wrapper {
  max-width: 1220px;
  margin: 0px auto;
}
.granite-logo-inner {
  max-width: 190px;
}
.granite-logo {
  margin-top: 4px;
}
.granite-logo {
  margin-bottom: 4px;
}
.granite-navigation-wrapper,
.granite-logo-right-text {
  margin-top: 48px;
}
.granite-navigation-wrapper .granite-main-menu > li > a {
  padding-bottom: 34px;
}
.top-social-wrapper .social-icon a {
  color: #fcfcfc;
}
h1,
h2,
h3,
h4,
h5,
h6,
.granite-title-font {
  font-family: 'Lato';
}
body,
input,
textarea,
select,
.granite-content-font {
  font-family: 'Lato';
}
.granite-navigation {
  font-family: 'Noto Sans';
}
.granite-testimonial-item.plain-style .testimonial-content {
  font-family: 'Merriweather';
}
.granite-testimonial-item.plain-style.granite-left .testimonial-item:before {
  font-family: 'Mystery Quest';
}
.granite-slider-item {
  font-family: 'Lato';
}
body {
  font-size: 14pt;
}
h1 {
  font-size: 47px;
}
h2 {
  font-size: 41px;
}
h3 {
  font-size: 32px;
}
h4 {
  font-size: 26px;
}
h5 {
  font-size: 21px;
}
h6 {
  font-size: 16px;
}
.top-navigation-wrapper {
  background-color: #272727;
}
.top-navigation-wrapper {
  color: #cecece;
}
.top-navigation-wrapper a {
  color: #f2f2f2;
}
.granite-header-inner,
.granite-header-wrapper.header-style-5-wrapper .granite-header-inner {
  background-color: rgba(37, 53, 38, 0.85);
}
@media only screen and (max-width: 767px) {
  .granite-header-wrapper.header-style-5-wrapper .granite-header-inner {
    background-color: #253526;
  }
}
.granite-main-menu > li > a,
.granite-main-menu > li > a i {
  color: #ffffff;
}
@media only screen and (max-width: 1100px) {
  body .granite-donate-button,
  body .granite-donate-button:hover,
  body .granite-top-woocommerce-button {
    color: #ffffff;
  }
}
.granite-main-menu > li:hover > a,
.granite-main-menu > li.current-menu-item > a,
.granite-main-menu > li.current-menu-ancestor > a {
  color: #90ba92;
  opacity: 1;
  filter: alpha(opacity=100);
}
.granite-navigation-gimmick {
  background-color: #90ba92;
}
.granite-button-overlay {
  background-color: #ecb338;
}
.granite-button-overlay {
  border-color: #8c6e16;
}
.granite-donate-button,
.granite-donate-button:hover {
  color: #685617;
}
.granite-woo-button-overlay {
  background-color: #f7c644;
}
.granite-woo-button-overlay {
  border-color: #8e7126;
}
.granite-top-woocommerce-button {
  color: #60511e;
}
.granite-main-menu > .granite-normal-menu .sub-menu,
.granite-main-menu > .granite-mega-menu .sf-mega {
  border-top-color: #1c1c1c;
}
.granite-main-menu > .granite-normal-menu li,
.granite-main-menu > .granite-mega-menu .sf-mega {
  background-color: #25221a;
}
.granite-main-menu > li > .sub-menu a,
.granite-main-menu > li > .sf-mega a {
  color: #f4f4f4;
}
.granite-main-menu > li > .sub-menu a:hover,
.granite-main-menu > li > .sub-menu .current-menu-item > a,
.granite-main-menu > li > .sub-menu .current-menu-ancestor > a,
.granite-main-menu > li > .sf-mega a:hover,
.granite-main-menu > li > .sf-mega .current-menu-item > a,
.granite-main-menu > li > .sf-mega .current-menu-ancestor > a {
  color: #eddbc0;
}
.granite-main-menu .granite-normal-menu li > a.sf-with-ul:after {
  border-left-color: #eddbc0;
}
.granite-main-menu .sf-mega-section-inner > ul > li > a:hover,
.granite-main-menu .sf-mega-section-inner > ul > li.current-menu-item > a {
  background-color: #332c20;
}
.granite-main-menu > li > .sub-menu *,
.granite-main-menu > li > .sf-mega * {
  border-color: #493e2b;
}
.granite-main-menu > li > .sf-mega .sf-mega-section-inner > a {
  color: #ffffff;
}
.granite-main-menu > li > .sf-mega .sf-mega-section-inner > a:hover,
.granite-main-menu > li > .sf-mega .sf-mega-section-inner.current-menu-item > a,
.granite-main-menu > li > .sf-mega .sf-mega-section-inner.current-menu-ancestor > a {
  color: #ffffff;
}
#granite-responsive-navigation.dl-menuwrapper button {
  background-color: #0c0c0c;
}
#granite-responsive-navigation.dl-menuwrapper button:hover,
#granite-responsive-navigation.dl-menuwrapper button.dl-active,
#granite-responsive-navigation.dl-menuwrapper ul {
  background-color: #161616;
}
body {
  background-color: #dddddd;
}
.body-wrapper,
.granite-single-lightbox-container {
  background-color: #ffffff;
}
.granite-page-title,
.granite-page-title-gimmick {
  color: #ffffff;
}
.granite-page-caption {
  color: #ffffff;
}
h1,
h2,
h3,
h4,
h5,
h6,
.granite-title,
.granite-title a {
  color: #3f3f3f;
}
.granite-item-title-wrapper .granite-item-title {
  color: #3f3f3f;
  border-color: #3f3f3f;
}
.granite-item-title-divider {
  border-color: #3f3f3f;
}
.granite-item-title-wrapper .granite-item-caption {
  color: #a5a3a3;
}
body {
  color: #808080;
}
a {
  color: #5dc269;
}
a:hover {
  color: #2c8e3b;
}
body * {
  border-color: #dddddd;
}
.page-not-found-block {
  background-color: #d65938;
}
.page-not-found-block {
  color: #ffffff;
}
.page-not-found-search .gdl-search-form input[type='text'] {
  background-color: #963a20;
}
.page-not-found-search .gdl-search-form input[type='text'] {
  color: #d57f5c;
}


.login-block {
  background-color: #eee;
}
.login-block {
  color: #3f3f3f;
}


.granite-sidebar .granite-widget-title {
  color: #383838;
}
.granite-sidebar * {
  border-color: #eeeeee;
}
.granite-sidebar ul li:before {
  border-color: #bdbdbd;
}
.gdl-search-form input {
  background-color: #f7f7f7;
}
.gdl-search-form input {
  color: #9d9d9d;
}
.gdl-search-form input {
  border-color: #ebebeb;
}
.tagcloud a {
  background-color: #5dc269;
}
.tagcloud a,
.tagcloud a:hover {
  color: #ffffff;
}
.granite-action-ads-item .action-ads-caption {
  color: #ffffff;
}
.granite-action-ads-item .action-ads-content {
  color: #ffffff;
}
.about-us-title {
  color: #3a3a3a;
}
.about-us-caption {
  color: #9c9c9c;
}
.granite-accordion-item.style-1 .pre-active .accordion-title {
  color: #3c3c3c;
}
.granite-accordion-item.style-1 .accordion-title {
  color: #8d8d8d;
}
.granite-accordion-item.style-1 .accordion-title i {
  background-color: #f3f3f3;
}
.granite-accordion-item.style-1 .accordion-title i {
  color: #a8a8a8;
}
.granite-accordion-item.style-1 .accordion-title i.icon-minus {
  background-color: #5dc269;
}
.granite-accordion-item.style-1 .accordion-title i.icon-minus {
  color: #ffffff;
}
.granite-banner-item-wrapper .flex-direction-nav .flex-prev,
.granite-banner-item-wrapper .flex-direction-nav .flex-next {
  color: #999999;
}
.granite-box-with-icon-item {
  background-color: #f5f5f5;
}
.granite-box-with-icon-item > i,
.granite-box-with-icon-item .box-with-icon-title {
  color: #585858;
}
.granite-box-with-icon-item {
  color: #929292;
}
.granite-button,
.granite-button:hover,
input[type='button'],
input[type='submit'],
.granite-top-menu > .granite-mega-menu .sf-mega a.granite-button {
  color: #ffffff;
}
.granite-button,
input[type='button'],
input[type='submit'] {
  background-color: #5dc269;
}
.granite-border-button {
  border-color: #5dc269;
  color: #5dc269;
}
.granite-button,
input[type='submit'] {
  border-color: #4b9e55;
}
.column-service-title {
  color: #5dc269;
}
.column-service-content {
  color: #909090;
}
.column-service-icon i {
  color: #5dc269;
}
.granite-column-service-item.granite-type-2 .column-service-icon {
  border-color: #5dc269;
}
.list-with-icon .list-with-icon-title {
  color: #333333;
}
.list-with-icon .list-with-icon-icon {
  border-color: #333333;
  color: #333333;
}
.granite-menu-title,
.granite-menu-icon,
.granite-menu-price {
  color: #5dc269;
}
.granite-menu-ingredients-caption {
  color: #818181;
}
.granite-list-menu-gimmick {
  border-color: #000000;
  color: #000000;
}
.granite-pie-chart-item .pie-chart-title {
  color: #313131;
}
.granite-price-inner-item {
  background-color: #f9f9f9;
}
.granite-price-item .price-title-wrapper {
  background-color: #454545;
}
.granite-price-item .price-title {
  color: #ffffff;
}
.granite-price-item .price-tag {
  background-color: #838383;
}
.granite-price-item .best-price .price-tag {
  background-color: #5dc269;
}
.granite-price-item .price-tag {
  color: #ffffff;
}
.granite-process-tab .granite-process-icon {
  background-color: #f3f3f3;
}
.granite-process-tab .granite-process-icon {
  border-color: #e3e3e3;
}
.granite-process-tab .granite-process-icon i {
  color: #aaaaaa;
}
.granite-process-tab .process-line .process-line-divider {
  border-color: #e3e3e3;
}
.granite-process-tab .process-line .icon-chevron-down,
.granite-process-tab .process-line .icon-chevron-right {
  color: #e3e3e3;
}
.granite-process-wrapper .granite-process-tab .granite-process-title {
  color: #454545;
}
.granite-skill-item-wrapper {
  color: #3a3a3a;
}
.stunning-text-title,
.stunning-item-title {
  color: #414141;
}
.stunning-text-caption,
.stunning-item-caption {
  color: #949494;
}
.granite-stunning-text-item.with-padding {
  background-color: #f3f3f3;
}
.granite-stunning-text-item.with-border {
  border-color: #f3f3f3;
}
.tab-title-wrapper .tab-title {
  background-color: #f9f9f9;
}
.tab-title-wrapper .tab-title {
  color: #3b3b3b;
}
.tab-title-wrapper .tab-title.active,
.tab-content-wrapper {
  background-color: #ffffff;
}
table tr th {
  background-color: #5dc269;
}
table tr th {
  color: #ffffff;
}
table.style-2 tr:nth-child(odd) {
  background-color: #f9f9f9;
}
table.style-2 tr:nth-child(odd) {
  color: #949494;
}
table.style-2 tr:nth-child(even) {
  background-color: #f3f3f3;
}
table.style-2 tr:nth-child(even) {
  color: #949494;
}
.granite-blog-title,
.granite-blog-title a {
  color: #5dc269;
}
.granite-blog-title a:hover {
  color: #3f873e;
}
.granite-blog-date-wrapper {
  color: #4e4e4e;
}
.blog-info,
.blog-info a,
.blog-info i {
  color: #b0b0b0;
}
.granite-blog-thumbnail .granite-sticky-banner {
  background-color: #232323;
}
.granite-blog-thumbnail .granite-sticky-banner,
.granite-blog-thumbnail .granite-sticky-banner i {
  color: #ffffff;
}
.granite-social-share,
.granite-social-share a {
  background-color: #f3f3f3;
}
.granite-social-share .social-share-title {
  color: #353535;
}
.granite-standard-style .granite-single-blog-tag a {
  background-color: #5dc269;
}
.granite-standard-style .granite-single-blog-tag a {
  color: #ffffff;
}
.format-aside .granite-blog-content {
  background-color: #5dc269;
}
.format-aside .granite-blog-content {
  color: #ffffff;
}
.format-quote .granite-top-quote blockquote {
  color: #8d8d8d;
}
.format-quote .granite-quote-author {
  color: #5dc269;
}
.granite-single-nav > div i {
  background-color: #f3f3f3;
}
.granite-single-nav > div i {
  color: #8d8d8d;
}
.portfolio-item-filter a {
  color: #5b5b5b;
}
.portfolio-item-filter a.active {
  color: #5dc269;
}
.granite-image-link-shortcode .granite-image-link-overlay,
.portfolio-thumbnail .portfolio-overlay {
  background-color: #1c1c1c;
}
.granite-image-link-shortcode .granite-image-link-icon,
.portfolio-thumbnail .portfolio-icon i,
.granite-image-link-shortcode .granite-image-frame-content,
.granite-modern-portfolio .portfolio-title a,
.granite-modern-portfolio .portfolio-title a:hover,
.granite-modern-portfolio .granite-portfolio-info a {
  border-color: #ffffff;
  color: #ffffff;
}
.portfolio-title a {
  color: #5dc269;
}
.portfolio-title a:hover {
  color: #3f873e;
}
.portfolio-info,
.portfolio-info a {
  color: #b0b0b0;
}
.granite-pagination .page-numbers {
  background-color: #ebebeb;
}
.granite-pagination .page-numbers {
  color: #979797;
}
.granite-pagination .page-numbers:hover,
.granite-pagination .page-numbers.current {
  background-color: #5dc269;
}
.granite-pagination .page-numbers:hover,
.granite-pagination .page-numbers.current {
  color: #ffffff;
}
.granite-personnel-item .personnel-item-inner {
  background-color: #f5f5f5;
}
.granite-personnel-item.round-style .personnel-item {
  background-color: #5dc269;
}
.granite-personnel-item .personnel-author {
  color: #3b3b3b;
}
.granite-personnel-item .personnel-author-image {
  border-color: #5dc269;
}
.granite-personnel-item .personnel-position {
  color: #acacac;
}
.granite-personnel-item .personnel-content {
  color: #959595;
}
.granite-personnel-item .personnel-social i {
  color: #3b3b3b;
}
.granite-testimonial-item .testimonial-item-inner,
.granite-testimonial-item .testimonial-author-image {
  background-color: #f5f5f5;
}
.granite-testimonial-item .testimonial-content {
  color: #9b9b9b;
}
.granite-testimonial-item.plain-style.granite-left .testimonial-item:before {
  color: #5dc269;
}
.granite-testimonial-item .testimonial-author {
  color: #5dc269;
}
.granite-testimonial-item .testimonial-position {
  color: #4d4d4d;
}
.granite-testimonial-item .testimonial-author-image {
  border-color: #5dc269;
}
.granite-testimonial-item.box-style .testimonial-item-inner:after {
  border-top-color: #dddddd;
  border-left-color: #dddddd;
}
.granite-gallery-thumbnail .gallery-item {
  background-color: #e5e5e5;
}
.granite-gallery-thumbnail-container .gallery-caption {
  background-color: #000000;
}
.granite-gallery-thumbnail-container .gallery-caption {
  color: #ffffff;
}
.nivo-controlNav a,
.flex-control-paging li a {
  background-color: #ffffff;
}
.nivo-controlNav a:hover,
.nivo-controlNav a.active,
.flex-control-paging li a:hover,
.flex-control-paging li a.flex-active {
  background-color: #5e5e5e;
}
.nivo-controlNav a,
.flex-control-paging li a {
  border-color: #5e5e5e !important;
}
.nivo-directionNav a,
.flex-direction-nav a,
.ls-flawless .ls-nav-prev,
.ls-flawless .ls-nav-next {
  background-color: #000000;
}
body .nivo-directionNav a,
body .flex-direction-nav a,
body .flex-direction-nav a:hover,
.ls-flawless .ls-nav-prev,
.ls-flawless .ls-nav-next {
  color: #ffffff;
}
.granite-caption {
  background-color: #000000;
}
.granite-caption-title {
  color: #ffffff;
}
.granite-caption-text {
  color: #ffffff;
}
.granite-caption-wrapper.post-slider {
  background-color: #000000;
}
.granite-caption-wrapper.post-slider .granite-caption-title {
  color: #ffffff;
}
.granite-caption-wrapper.post-slider .granite-caption-text {
  color: #dddddd;
}
.granite-post-slider-item.style-no-excerpt .granite-caption-wrapper .granite-caption-date,
.granite-post-slider-item.style-no-excerpt .granite-caption-wrapper .granite-title-link {
  color: #ffffff;
}
.granite-post-slider-item.style-no-excerpt .granite-caption-wrapper .granite-caption-date,
.granite-post-slider-item.style-no-excerpt .granite-caption-wrapper .granite-title-link {
  background-color: #5dc269;
}
.granite-item-title-wrapper .granite-flex-prev,
.granite-item-title-wrapper .granite-flex-next {
  color: #6d6d6d;
}
input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  background-color: #f5f5f5;
}
input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  color: #797979;
}
input::input-placeholder {
  color: #797979;
}
input::-webkit-input-placeholder {
  color: #797979;
}
input::-moz-placeholder {
  color: #797979;
}
input:-moz-placeholder {
  color: #797979;
}
input:-ms-input-placeholder {
  color: #797979;
}
textarea::input-placeholder {
  color: #797979;
}
textarea::-webkit-input-placeholder {
  color: #797979;
}
textarea::-moz-placeholder {
  color: #797979;
}
textarea:-moz-placeholder {
  color: #797979;
}
textarea:-ms-input-placeholder {
  color: #797979;
}
.footer-wrapper {
  background-color: #231e18;
}
.footer-wrapper .granite-widget-title,
.footer-wrapper .granite-widget-title a {
  color: #dddddd;
}
.footer-wrapper {
  color: #bfbfbf;
}
.footer-wrapper a {
  color: #7f7f7f;
}
.footer-wrapper a:hover {
  color: #cecece;
}
.footer-wrapper * {
  border-color: #382f24;
}
.footer-wrapper input[type='text'],
.footer-wrapper input[type='email'],
.footer-wrapper input[type='password'],
.footer-wrapper textarea {
  background-color: #141414;
}
.footer-wrapper input[type='text'],
.footer-wrapper input[type='email'],
.footer-wrapper input[type='password'],
.footer-wrapper textarea {
  color: #828282;
}
.footer-wrapper input[type='text'],
.footer-wrapper input[type='email'],
.footer-wrapper input[type='password'],
.footer-wrapper textarea {
  border-color: #313131;
}
.footer-wrapper .granite-button,
.footer-wrapper .granite-button:hover,
.footer-wrapper input[type='button'],
.footer-wrapper input[type='submit'] {
  color: #ffffff;
}
.footer-wrapper .granite-button,
.footer-wrapper input[type='button'],
.footer-wrapper input[type='submit'] {
  background-color: #5dc269;
}
.footer-wrapper .tagcloud a {
  background-color: #5dc269;
}
.footer-wrapper .tagcloud a,
.footer-wrapper .tagcloud a:hover {
  color: #ffffff;
}
.copyright-wrapper {
  background-color: #191612;
}
.copyright-wrapper {
  color: #999999;
}
.footer-wrapper .copyright-wrapper {
  border-color: #191612;
}
html .woocommerce span.onsale,
html .woocommerce-page span.onsale,
html .woocommerce-message,
html .woocommerce div.product .woocommerce-tabs ul.tabs li.active,
html .woocommerce #content div.product .woocommerce-tabs ul.tabs li.active,
html .woocommerce-page div.product .woocommerce-tabs ul.tabs li.active,
html .woocommerce-page #content div.product .woocommerce-tabs ul.tabs li.active {
  background: #5dc269;
}
html .woocommerce .star-rating,
html .woocommerce-page .star-rating,
html .woocommerce .star-rating:before,
html .woocommerce-page .star-rating:before,
html .woocommerce div.product span.price,
html .woocommerce div.product p.price,
html .woocommerce #content div.product span.price,
html .woocommerce #content div.product p.price,
html .woocommerce-page div.product span.price,
html .woocommerce-page div.product p.price,
html .woocommerce-page #content div.product span.price,
html .woocommerce-page #content div.product p.price {
  color: #5dc269;
}
html .woocommerce-message a.button,
html .woocommerce-error a.button,
html .woocommerce-info a.button,
html .woocommerce-message,
html .woocommerce-error,
html .woocommerce-info,
html .woocommerce span.onsale,
html .woocommerce-page span.onsale,
html .woocommerce div.product .woocommerce-tabs ul.tabs li.active,
html .woocommerce #content div.product .woocommerce-tabs ul.tabs li.active,
html .woocommerce-page div.product .woocommerce-tabs ul.tabs li.active,
html .woocommerce-page #content div.product .woocommerce-tabs ul.tabs li.active,
html .woocommerce nav.woocommerce-pagination ul li span.current,
html .woocommerce-page nav.woocommerce-pagination ul li span.current,
html .woocommercenav.woocommerce-pagination ul li a:hover,
html .woocommerce-page nav.woocommerce-pagination ul li a:hover {
  color: #ffffff;
}
html .woocommerce-info {
  background: #389ec5;
}
html .woocommerce-error {
  background: #c23030;
}
html .woocommerce a.button.alt:hover,
html .woocommerce button.button.alt:hover,
html .woocommerce input.button.alt:hover,
html .woocommerce #respond input#submit.alt:hover,
html .woocommerce #content input.button.alt:hover,
html .woocommerce-page a.button.alt:hover,
html .woocommerce-page button.button.alt:hover,
html .woocommerce-page input.button.alt:hover,
html .woocommerce-page #respond input#submit.alt:hover,
html .woocommerce-page #content input.button.alt:hover,
html .woocommerce a.button.alt,
html .woocommerce button.button.alt,
html .woocommerce input.button.alt,
html .woocommerce #respond input#submit.alt,
html .woocommerce #content input.button.alt,
html .woocommerce-page a.button.alt,
html .woocommerce-page button.button.alt,
html .woocommerce-page input.button.alt,
html .woocommerce-page #respond input#submit.alt,
html .woocommerce-page #content input.button.alt,
html .woocommerce a.button,
html .woocommerce button.button,
html .woocommerce input.button,
html .woocommerce #respond input#submit,
html .woocommerce #content input.button,
html .woocommerce-page a.button,
html .woocommerce-page button.button,
html .woocommerce-page input.button,
html .woocommerce-page #respond input#submit,
html .woocommerce-page #content input.button,
html .woocommerce a.button:hover,
html .woocommerce button.button:hover,
html .woocommerce input.button:hover,
html .woocommerce #respond input#submit:hover,
html .woocommerce #content input.button:hover,
html .woocommerce-page a.button:hover,
html .woocommerce-page button.button:hover,
html .woocommerce-page input.button:hover,
html .woocommerce-page #respond input#submit:hover,
html .woocommerce-page #content input.button:hover,
html .woocommerce ul.products li.product a.loading,
html .woocommerce div.product form.cart .button,
html .woocommerce #content div.product form.cart .button,
html .woocommerce-page div.product form.cart .button,
html .woocommerce-page #content div.product form.cart .button {
  background: #5dc269;
}
html .woocommerce a.button.alt:hover,
html .woocommerce button.button.alt:hover,
html .woocommerce input.button.alt:hover,
html .woocommerce #respond input#submit.alt:hover,
html .woocommerce #content input.button.alt:hover,
html .woocommerce-page a.button.alt:hover,
html .woocommerce-page button.button.alt:hover,
html .woocommerce-page input.button.alt:hover,
html .woocommerce-page #respond input#submit.alt:hover,
html .woocommerce-page #content input.button.alt:hover,
html .woocommerce a.button.alt,
html .woocommerce button.button.alt,
html .woocommerce input.button.alt,
html .woocommerce #respond input#submit.alt,
html .woocommerce #content input.button.alt,
html .woocommerce-page a.button.alt,
html .woocommerce-page button.button.alt,
html .woocommerce-page input.button.alt,
html .woocommerce-page #respond input#submit.alt,
html .woocommerce-page #content input.button.alt,
html .woocommerce a.button,
html .woocommerce button.button,
html .woocommerce input.button,
html .woocommerce #respond input#submit,
html .woocommerce #content input.button,
html .woocommerce-page a.button,
html .woocommerce-page button.button,
html .woocommerce-page input.button,
html .woocommerce-page #respond input#submit,
html .woocommerce-page #content input.button,
html .woocommerce a.button:hover,
html .woocommerce button.button:hover,
html .woocommerce input.button:hover,
html .woocommerce #respond input#submit:hover,
html .woocommerce #content input.button:hover,
html .woocommerce-page a.button:hover,
html .woocommerce-page button.button:hover,
html .woocommerce-page input.button:hover,
html .woocommerce-page #respond input#submit:hover,
html .woocommerce-page #content input.button:hover,
html .woocommerce ul.products li.product a.loading,
html .woocommerce div.product form.cart .button,
html .woocommerce #content div.product form.cart .button,
html .woocommerce-page div.product form.cart .button,
html .woocommerce-page #content div.product form.cart .button {
  color: #ffffff;
}
html .woocommerce a.button.alt:hover,
html .woocommerce button.button.alt:hover,
html .woocommerce input.button.alt:hover,
html .woocommerce #respond input#submit.alt:hover,
html .woocommerce #content input.button.alt:hover,
html .woocommerce-page a.button.alt:hover,
html .woocommerce-page button.button.alt:hover,
html .woocommerce-page input.button.alt:hover,
html .woocommerce-page #respond input#submit.alt:hover,
html .woocommerce-page #content input.button.alt:hover,
html .woocommerce a.button.alt,
html .woocommerce button.button.alt,
html .woocommerce input.button.alt,
html .woocommerce #respond input#submit.alt,
html .woocommerce #content input.button.alt,
html .woocommerce-page a.button.alt,
html .woocommerce-page button.button.alt,
html .woocommerce-page input.button.alt,
html .woocommerce-page #respond input#submit.alt,
html .woocommerce-page #content input.button.alt,
html .woocommerce a.button,
html .woocommerce button.button,
html .woocommerce input.button,
html .woocommerce #respond input#submit,
html .woocommerce #content input.button,
html .woocommerce-page a.button,
html .woocommerce-page button.button,
html .woocommerce-page input.button,
html .woocommerce-page #respond input#submit,
html .woocommerce-page #content input.button,
html .woocommerce a.button:hover,
html .woocommerce button.button:hover,
html .woocommerce input.button:hover,
html .woocommerce #respond input#submit:hover,
html .woocommerce #content input.button:hover,
html .woocommerce-page a.button:hover,
html .woocommerce-page button.button:hover,
html .woocommerce-page input.button:hover,
html .woocommerce-page #respond input#submit:hover,
html .woocommerce-page #content input.button:hover,
html .woocommerce ul.products li.product a.loading,
html .woocommerce div.product form.cart .button,
html .woocommerce #content div.product form.cart .button,
html .woocommerce-page div.product form.cart .button,
html .woocommerce-page #content div.product form.cart .button {
  border-bottom: 3px solid #3ca348;
}
html .woocommerce #reviews #comments ol.commentlist li img.avatar,
html .woocommerce-page #reviews #comments ol.commentlist li img.avatar {
  background: #ebebeb;
}
html .woocommerce #reviews #comments ol.commentlist li img.avatar,
html .woocommerce-page #reviews #comments ol.commentlist li img.avatar,
html .woocommerce #reviews #comments ol.commentlist li .comment-text,
html .woocommerce-page #reviews #comments ol.commentlist li .comment-text,
html .woocommerce ul.products li.product a img,
html .woocommerce-page ul.products li.product a img,
html .woocommerce ul.products li.product a img:hover,
html .woocommerce-page ul.products li.product a img:hover,
html .woocommerce-page div.product div.images img,
html .woocommerce-page #content div.product div.images img,
html .woocommerce form.login,
html .woocommerce form.checkout_coupon,
html .woocommerce form.register,
html .woocommerce-page form.login,
html .woocommerce-page form.checkout_coupon,
html .woocommerce-page form.register,
html .woocommerce table.cart td.actions .coupon .input-text,
html .woocommerce #content table.cart td.actions .coupon .input-text,
html .woocommerce-page table.cart td.actions .coupon .input-text,
html .woocommerce-page #content table.cart td.actions .coupon .input-text {
  border: 1px solid #ebebeb;
}
html .woocommerce div.product .woocommerce-tabs ul.tabs:before,
html .woocommerce #content div.product .woocommerce-tabs ul.tabs:before,
html .woocommerce-page div.product .woocommerce-tabs ul.tabs:before,
html .woocommerce-page #content div.product .woocommerce-tabs ul.tabs:before,
html .woocommerce table.shop_table tfoot td,
html .woocommerce table.shop_table tfoot th,
html .woocommerce-page table.shop_table tfoot td,
html .woocommerce-page table.shop_table tfoot th,
html .woocommerce table.shop_table tfoot td,
html .woocommerce table.shop_table tfoot th,
html .woocommerce-page table.shop_table tfoot td,
html .woocommerce-page table.shop_table tfoot th {
  border-bottom: 1px solid #ebebeb;
}
html .woocommerce .cart-collaterals .cart_totals table tr:first-child th,
html .woocommerce .cart-collaterals .cart_totals table tr:first-child td,
html .woocommerce-page .cart-collaterals .cart_totals table tr:first-child th,
html .woocommerce-page .cart-collaterals .cart_totals table tr:first-child td {
  border-top: 3px #ebebeb solid;
}
html .woocommerce .cart-collaterals .cart_totals tr td,
html .woocommerce .cart-collaterals .cart_totals tr th,
html .woocommerce-page .cart-collaterals .cart_totals tr td,
html .woocommerce-page .cart-collaterals .cart_totals tr th {
  border-bottom: 2px solid #ebebeb;
}
html .woocommerce div.product .woocommerce-tabs ul.tabs li,
html .woocommerce #content div.product .woocommerce-tabs ul.tabs li,
html .woocommerce-page div.product .woocommerce-tabs ul.tabs li,
html .woocommerce-page #content div.product .woocommerce-tabs ul.tabs li,
html .woocommerce table.cart a.remove,
html .woocommerce #content table.cart a.remove,
html .woocommerce-page table.cart a.remove,
html .woocommerce-page #content table.cart a.remove,
html .woocommerce #payment,
html .woocommerce-page #payment,
html .woocommerce .customer_details,
html .woocommerce ul.order_details,
html .woocommerce nav.woocommerce-pagination ul li a,
html .woocommerce-page nav.woocommerce-pagination ul li a,
html .woocommerce form .form-row input.input-text,
html .woocommerce form .form-row textarea,
html .woocommerce-page form .form-row input.input-text,
html .woocommerce-page form .form-row textarea,
html .woocommerce .quantity input.qty,
html .woocommerce #content .quantity input.qty,
html .woocommerce-page .quantity input.qty,
html .woocommerce-page #content .quantity input.qty,
html .woocommerce .widget_shopping_cart .total,
html .woocommerce-page .widget_shopping_cart .total {
  background: #f3f3f3;
}
html .woocommerce .quantity input.qty,
html .woocommerce #content .quantity input.qty,
html .woocommerce-page .quantity input.qty,
html .woocommerce-page #content .quantity input.qty {
  border: 1px solid #f3f3f3;
}
html .woocommerce .widget_shopping_cart .total,
html .woocommerce-page .widget_shopping_cart .total {
  border-top: 2px solid #e5e5e5;
}
html .woocommerce table.cart a.remove:hover,
html .woocommerce #content table.cart a.remove:hover,
html .woocommerce-page table.cart a.remove:hover,
html .woocommerce-page #content table.cart a.remove:hover,
html #payment div.payment_box,
html .woocommerce-page #payment div.payment_box {
  background: #e5e5e5;
}
html .woocommerce table.shop_table tfoot td,
html .woocommerce table.shop_table tfoot th,
html .woocommerce-page table.shop_table tfoot td,
html .woocommerce-page table.shop_table tfoot th,
.cart-subtotal th,
.shipping th,
.total th,
html .woocommerce table.shop_attributes .alt th,
html .woocommerce-page table.shop_attributes .alt th,
html .woocommerce ul.products li.product .price,
html.woocommerce-page ul.products li.product .price {
  color: #333333;
}
html .woocommerce ul.products li.product .price del,
html .woocommerce-page ul.products li.product .price del,
html .woocommerce table.cart a.remove,
html .woocommerce #content table.cart a.remove,
html .woocommerce-page table.cart a.remove,
html .woocommerce-page #content table.cart a.remove {
  color: #888888;
}
html .woocommerce .quantity .plus,
html .woocommerce .quantity .minus,
html .woocommerce #content .quantity .plus,
html .woocommerce #content .quantity .minus,
html .woocommerce-page .quantity .plus,
html .woocommerce-page .quantity .minus,
html .woocommerce-page #content .quantity .plus,
html .woocommerce-page #content .quantity .minus {
  border: 1px solid #a0a0a0;
}
html .woocommerce .quantity .plus,
html .woocommerce .quantity .minus,
html .woocommerce #content .quantity .plus,
html .woocommerce #content .quantity .minus,
html .woocommerce-page .quantity .plus,
html .woocommerce-page .quantity .minus,
html .woocommerce-page #content .quantity .plus,
html .woocommerce-page #content .quantity .minus {
  color: #ffffff;
}
html .woocommerce .quantity .plus,
html .woocommerce #content .quantity .plus,
html .woocommerce-page .quantity .plus,
html .woocommerce-page #content .quantity .plus,
html .woocommerce .quantity .plus:hover,
html .woocommerce #content .quantity .plus:hover,
html .woocommerce-page .quantity .plus:hover,
html .woocommerce-page #content .quantity .plus:hover {
  background: #9a9a9a;
}
html .woocommerce .quantity .minus,
html .woocommerce #content .quantity .minus,
html .woocommerce-page .quantity .minus,
html .woocommerce-page #content .quantity .minus,
html .woocommerce .quantity .minus:hover,
html .woocommerce #content .quantity .minus:hover,
html .woocommerce-page .quantity .minus:hover,
html .woocommerce-page #content .quantity .minus:hover {
  background: #b6b6b6;
}
.half-container {
  max-width: 570px;
}
.granite-skin-dark-skin,
.granite-skin-dark-skin .granite-skin-content {
  color: #c4c4c4;
}
.granite-skin-dark-skin i,
.granite-skin-dark-skin .granite-flex-prev,
.granite-skin-dark-skin .granite-flex-next {
  color: #ffffff;
}
.granite-skin-dark-skin h1,
.granite-skin-dark-skin h2,
.granite-skin-dark-skin h3,
.granite-skin-dark-skin h4,
.granite-skin-dark-skin h5,
.granite-skin-dark-skin h6,
.granite-skin-dark-skin .granite-skin-title,
.granite-skin-dark-skin .granite-skin-title a {
  color: #ffffff;
}
.granite-skin-dark-skin .granite-skin-title a:hover {
  color: #ffffff;
}
.granite-skin-dark-skin .granite-skin-info,
.granite-skin-dark-skin .granite-skin-info a,
.granite-skin-dark-skin .granite-skin-info a:hover {
  color: #ffffff;
}
.granite-skin-dark-skin a,
.granite-skin-dark-skin .granite-skin-link,
.granite-skin-dark-skin .granite-skin-link-color {
  color: #ffffff;
}
.granite-skin-dark-skin a:hover,
.granite-skin-dark-skin .granite-skin-link:hover {
  color: #d1d1d1;
}
.granite-skin-dark-skin .granite-skin-box,
.granite-skin-dark-skin .granite-column-service-item .granite-skin-box {
  background-color: #969696;
}
.granite-skin-dark-skin *,
.granite-skin-dark-skin .granite-skin-border {
  border-color: #828282;
}
.granite-skin-dark-skin .granite-button,
.granite-skin-dark-skin .granite-button:hover,
.granite-skin-dark-skin input[type='button'],
.granite-skin-dark-skin input[type='submit'] {
  color: #ffffff;
  background-color: #5dc269;
}
.granite-skin-dark-testimonial,
.granite-skin-dark-testimonial .granite-skin-content {
  color: #ffffff;
}
.granite-skin-dark-testimonial i,
.granite-skin-dark-testimonial .granite-flex-prev,
.granite-skin-dark-testimonial .granite-flex-next {
  color: #ffffff;
}
.granite-skin-dark-testimonial h1,
.granite-skin-dark-testimonial h2,
.granite-skin-dark-testimonial h3,
.granite-skin-dark-testimonial h4,
.granite-skin-dark-testimonial h5,
.granite-skin-dark-testimonial h6,
.granite-skin-dark-testimonial .granite-skin-title,
.granite-skin-dark-testimonial .granite-skin-title a {
  color: #ffffff;
}
.granite-skin-dark-testimonial .granite-skin-title a:hover {
  color: #ffffff;
}
.granite-skin-dark-testimonial .granite-skin-info,
.granite-skin-dark-testimonial .granite-skin-info a,
.granite-skin-dark-testimonial .granite-skin-info a:hover {
  color: #ffffff;
}
.granite-skin-dark-testimonial a,
.granite-skin-dark-testimonial .granite-skin-link,
.granite-skin-dark-testimonial .granite-skin-link-color {
  color: #e36d39;
}
.granite-skin-dark-testimonial a:hover,
.granite-skin-dark-testimonial .granite-skin-link:hover {
  color: #ffffff;
}
.granite-skin-dark-testimonial .granite-skin-box,
.granite-skin-dark-testimonial .granite-column-service-item .granite-skin-box {
  background-color: #2b2b2b;
}
.granite-skin-dark-testimonial *,
.granite-skin-dark-testimonial .granite-skin-border {
  border-color: #ffffff;
}
.granite-skin-dark-testimonial .granite-button,
.granite-skin-dark-testimonial .granite-button:hover,
.granite-skin-dark-testimonial input[type='button'],
.granite-skin-dark-testimonial input[type='submit'] {
  color: #ffffff;
  background-color: #ffffff;
}
.granite-skin-light-grey,
.granite-skin-light-grey .granite-skin-content {
  color: #999999;
}
.granite-skin-light-grey i,
.granite-skin-light-grey .granite-flex-prev,
.granite-skin-light-grey .granite-flex-next {
  color: #e0e0e0;
}
.granite-skin-light-grey h1,
.granite-skin-light-grey h2,
.granite-skin-light-grey h3,
.granite-skin-light-grey h4,
.granite-skin-light-grey h5,
.granite-skin-light-grey h6,
.granite-skin-light-grey .granite-skin-title,
.granite-skin-light-grey .granite-skin-title a {
  color: #353535;
}
.granite-skin-light-grey .granite-skin-title a:hover {
  color: #5e5e5e;
}
.granite-skin-light-grey .granite-skin-info,
.granite-skin-light-grey .granite-skin-info a,
.granite-skin-light-grey .granite-skin-info a:hover {
  color: #828282;
}
.granite-skin-light-grey a,
.granite-skin-light-grey .granite-skin-link,
.granite-skin-light-grey .granite-skin-link-color {
  color: #5dc269;
}
.granite-skin-light-grey a:hover,
.granite-skin-light-grey .granite-skin-link:hover {
  color: #7fc188;
}
.granite-skin-light-grey .granite-skin-box,
.granite-skin-light-grey .granite-column-service-item .granite-skin-box {
  background-color: #a5a5a5;
}
.granite-skin-light-grey *,
.granite-skin-light-grey .granite-skin-border {
  border-color: #eaeaea;
}
.granite-skin-light-grey .granite-button,
.granite-skin-light-grey .granite-button:hover,
.granite-skin-light-grey input[type='button'],
.granite-skin-light-grey input[type='submit'] {
  color: #a3a3a3;
  background-color: #f2f2f2;
}
.granite-skin-service-half,
.granite-skin-service-half .granite-skin-content {
  color: #909090;
}
.granite-skin-service-half i,
.granite-skin-service-half .granite-flex-prev,
.granite-skin-service-half .granite-flex-next {
  color: #5dc269;
}
.granite-skin-service-half h1,
.granite-skin-service-half h2,
.granite-skin-service-half h3,
.granite-skin-service-half h4,
.granite-skin-service-half h5,
.granite-skin-service-half h6,
.granite-skin-service-half .granite-skin-title,
.granite-skin-service-half .granite-skin-title a {
  color: #5dc269;
}
.granite-skin-service-half .granite-skin-title a:hover {
  color: #5dc269;
}
.granite-skin-service-half .granite-skin-info,
.granite-skin-service-half .granite-skin-info a,
.granite-skin-service-half .granite-skin-info a:hover {
  color: #adadad;
}
.granite-skin-service-half a,
.granite-skin-service-half .granite-skin-link,
.granite-skin-service-half .granite-skin-link-color {
  color: #5dc269;
}
.granite-skin-service-half a:hover,
.granite-skin-service-half .granite-skin-link:hover {
  color: #5dc269;
}
.granite-skin-service-half .granite-skin-box,
.granite-skin-service-half .granite-column-service-item .granite-skin-box {
  background-color: #ffffff;
}
.granite-skin-service-half *,
.granite-skin-service-half .granite-skin-border {
  border-color: #e5e5e5;
}
.granite-skin-service-half .granite-button,
.granite-skin-service-half .granite-button:hover,
.granite-skin-service-half input[type='button'],
.granite-skin-service-half input[type='submit'] {
  color: #ffffff;
  background-color: #5dc269;
}
.granite-skin-newsletter,
.granite-skin-newsletter .granite-skin-content {
  color: #ffffff;
}
.granite-skin-newsletter i,
.granite-skin-newsletter .granite-flex-prev,
.granite-skin-newsletter .granite-flex-next {
  color: #ffffff;
}
.granite-skin-newsletter h1,
.granite-skin-newsletter h2,
.granite-skin-newsletter h3,
.granite-skin-newsletter h4,
.granite-skin-newsletter h5,
.granite-skin-newsletter h6,
.granite-skin-newsletter .granite-skin-title,
.granite-skin-newsletter .granite-skin-title a {
  color: #fec428;
}
.granite-skin-newsletter .granite-skin-title a:hover {
  color: #fec428;
}
.granite-skin-newsletter .granite-skin-info,
.granite-skin-newsletter .granite-skin-info a,
.granite-skin-newsletter .granite-skin-info a:hover {
  color: #ffffff;
}
.granite-skin-newsletter a,
.granite-skin-newsletter .granite-skin-link,
.granite-skin-newsletter .granite-skin-link-color {
  color: #ffffff;
}
.granite-skin-newsletter a:hover,
.granite-skin-newsletter .granite-skin-link:hover {
  color: #ffffff;
}
.granite-skin-newsletter .granite-skin-box,
.granite-skin-newsletter .granite-column-service-item .granite-skin-box {
  background-color: #ffffff;
}
.granite-skin-newsletter *,
.granite-skin-newsletter .granite-skin-border {
  border-color: #ffffff;
}
.granite-skin-newsletter .granite-button,
.granite-skin-newsletter .granite-button:hover,
.granite-skin-newsletter input[type='button'],
.granite-skin-newsletter input[type='submit'] {
  color: #ffffff;
  background-color: #5dc269;
}
.granite-skin-brown-column-service,
.granite-skin-brown-column-service .granite-skin-content {
  color: #ffffff;
}
.granite-skin-brown-column-service i,
.granite-skin-brown-column-service .granite-flex-prev,
.granite-skin-brown-column-service .granite-flex-next {
  color: #ffffff;
}
.granite-skin-brown-column-service h1,
.granite-skin-brown-column-service h2,
.granite-skin-brown-column-service h3,
.granite-skin-brown-column-service h4,
.granite-skin-brown-column-service h5,
.granite-skin-brown-column-service h6,
.granite-skin-brown-column-service .granite-skin-title,
.granite-skin-brown-column-service .granite-skin-title a {
  color: #ffffff;
}
.granite-skin-brown-column-service .granite-skin-title a:hover {
  color: #ffffff;
}
.granite-skin-brown-column-service .granite-skin-info,
.granite-skin-brown-column-service .granite-skin-info a,
.granite-skin-brown-column-service .granite-skin-info a:hover {
  color: #ffffff;
}
.granite-skin-brown-column-service a,
.granite-skin-brown-column-service .granite-skin-link,
.granite-skin-brown-column-service .granite-skin-link-color {
  color: #ffffff;
}
.granite-skin-brown-column-service a:hover,
.granite-skin-brown-column-service .granite-skin-link:hover {
  color: #ffffff;
}
.granite-skin-brown-column-service .granite-skin-box,
.granite-skin-brown-column-service .granite-column-service-item .granite-skin-box {
  background-color: #ffffff;
}
.granite-skin-brown-column-service *,
.granite-skin-brown-column-service .granite-skin-border {
  border-color: #ffffff;
}
.granite-skin-brown-column-service .granite-button,
.granite-skin-brown-column-service .granite-button:hover,
.granite-skin-brown-column-service input[type='button'],
.granite-skin-brown-column-service input[type='submit'] {
  color: #ffffff;
  background-color: #ffffff;
}

/*
===============================================================
 # CUSTOM CSS
 - Please do not edit this file. this file is generated by server-side code
 - Every changes here will be overwritten
===============================================================*/

.msp-preset-btn-84 {
  background-color: #b97ebb;
  color: #fff;
}
.msp-preset-btn-84:hover {
  background-color: #ca89cc;
  color: #fff;
}
.msp-preset-btn-84:active {
  top: 1px;
}
.msp-preset-btn-85 {
  background-color: #b97ebb;
  color: #fff;
}
.msp-preset-btn-85:hover {
  background-color: #ca89cc;
  color: #fff;
}
.msp-preset-btn-85:active {
  top: 1px;
}
.msp-preset-btn-86 {
  color: #b97ebb;
  border: solid 1px #b97ebb;
}
.msp-preset-btn-86:hover {
  border-color: #ca89cc;
  color: #ca89cc;
}
.msp-preset-btn-86:active {
  top: 1px;
}
.msp-preset-btn-87 {
  background-color: #b97ebb;
  color: #fff;
}
.msp-preset-btn-87:hover {
  background-color: #ca89cc;
  color: #fff;
}
.msp-preset-btn-87:active {
  top: 1px;
}
.msp-preset-btn-88 {
  background-color: #b97ebb;
  color: #fff;
  box-shadow: 0 5px #9a699c;
}
.msp-preset-btn-88:hover {
  background-color: #ca89cc;
  box-shadow: 0 4px #9a699c;
  top: 1px;
  color: #fff;
}
.msp-preset-btn-88:active {
  box-shadow: 0 2px #9a699c;
  top: 3px;
}
.msp-preset-btn-89 {
  background-color: #5472d2;
  color: #fff;
}
.msp-preset-btn-89:hover {
  background-color: #5d7fe9;
  color: #fff;
}
.msp-preset-btn-89:active {
  top: 1px;
}
.msp-preset-btn-90 {
  background-color: #5472d2;
  color: #fff;
}
.msp-preset-btn-90:hover {
  background-color: #5d7fe9;
  color: #fff;
}
.msp-preset-btn-90:active {
  top: 1px;
}
.msp-preset-btn-91 {
  color: #5472d2;
  border: solid 1px #5472d2;
}
.msp-preset-btn-91:hover {
  border-color: #5d7fe9;
  color: #5d7fe9;
}
.msp-preset-btn-91:active {
  top: 1px;
}
.msp-preset-btn-92 {
  background-color: #5472d2;
  color: #fff;
}
.msp-preset-btn-92:hover {
  background-color: #5d7fe9;
  color: #fff;
}
.msp-preset-btn-92:active {
  top: 1px;
}
.msp-preset-btn-93 {
  background-color: #5472d2;
  color: #fff;
  box-shadow: 0 5px #4c68be;
}
.msp-preset-btn-93:hover {
  background-color: #5d7fe9;
  box-shadow: 0 4px #4c68be;
  top: 1px;
  color: #fff;
}
.msp-preset-btn-93:active {
  box-shadow: 0 2px #4c68be;
  top: 3px;
}
.msp-preset-btn-94 {
  background-color: #00c1cf;
  color: #fff;
}
.msp-preset-btn-94:hover {
  background-color: #01d4e4;
  color: #fff;
}
.msp-preset-btn-94:active {
  top: 1px;
}
.msp-preset-btn-95 {
  background-color: #00c1cf;
  color: #fff;
}
.msp-preset-btn-95:hover {
  background-color: #01d4e4;
  color: #fff;
}
.msp-preset-btn-95:active {
  top: 1px;
}
.msp-preset-btn-96 {
  color: #00c1cf;
  border: solid 1px #00c1cf;
}
.msp-preset-btn-96:hover {
  border-color: #01d4e4;
  color: #01d4e4;
}
.msp-preset-btn-96:active {
  top: 1px;
}
.msp-preset-btn-97 {
  background-color: #00c1cf;
  color: #fff;
}
.msp-preset-btn-97:hover {
  background-color: #01d4e4;
  color: #fff;
}
.msp-preset-btn-97:active {
  top: 1px;
}
.msp-preset-btn-98 {
  background-color: #00c1cf;
  color: #fff;
  box-shadow: 0 5px #00afbc;
}
.msp-preset-btn-98:hover {
  background-color: #01d4e4;
  box-shadow: 0 4px #00afbc;
  top: 1px;
  color: #fff;
}
.msp-preset-btn-98:active {
  box-shadow: 0 2px #00afbc;
  top: 3px;
}
.msp-preset-btn-99 {
  background-color: #4cadc9;
  color: #fff;
}
.msp-preset-btn-99:hover {
  background-color: #63b2c9;
  color: #fff;
}
.msp-preset-btn-99:active {
  top: 1px;
}
.msp-preset-btn-100 {
  background-color: #4cadc9;
  color: #fff;
}
.msp-preset-btn-100:hover {
  background-color: #63b2c9;
  color: #fff;
}
.msp-preset-btn-100:active {
  top: 1px;
}
.msp-preset-btn-101 {
  color: #4cadc9;
  border: solid 1px #4cadc9;
}
.msp-preset-btn-101:hover {
  border-color: #63b2c9;
  color: #63b2c9;
}
.msp-preset-btn-101:active {
  top: 1px;
}
.msp-preset-btn-102 {
  background-color: #4cadc9;
  color: #fff;
}
.msp-preset-btn-102:hover {
  background-color: #63b2c9;
  color: #fff;
}
.msp-preset-btn-102:active {
  top: 1px;
}
.msp-preset-btn-103 {
  background-color: #4cadc9;
  color: #fff;
  box-shadow: 0 5px #1aa2c9;
}
.msp-preset-btn-103:hover {
  background-color: #63b2c9;
  box-shadow: 0 4px #1aa2c9;
  top: 1px;
  color: #fff;
}
.msp-preset-btn-103:active {
  box-shadow: 0 2px #1aa2c9;
  top: 3px;
}
.msp-preset-btn-104 {
  background-color: #cec2ab;
  color: #fff;
}
.msp-preset-btn-104:hover {
  background-color: #cebd9d;
  color: #fff;
}
.msp-preset-btn-104:active {
  top: 1px;
}
.msp-preset-btn-105 {
  background-color: #cec2ab;
  color: #fff;
}
.msp-preset-btn-105:hover {
  background-color: #cebd9d;
  color: #fff;
}
.msp-preset-btn-105:active {
  top: 1px;
}
.msp-preset-btn-106 {
  color: #cec2ab;
  border: solid 1px #cec2ab;
}
.msp-preset-btn-106:hover {
  border-color: #cebd9d;
  color: #cebd9d;
}
.msp-preset-btn-106:active {
  top: 1px;
}
.msp-preset-btn-107 {
  background-color: #cec2ab;
  color: #fff;
}
.msp-preset-btn-107:hover {
  background-color: #cebd9d;
  color: #fff;
}
.msp-preset-btn-107:active {
  top: 1px;
}
.msp-preset-btn-108 {
  background-color: #cec2ab;
  color: #fff;
  box-shadow: 0 5px #c2b7a2;
}
.msp-preset-btn-108:hover {
  background-color: #cebd9d;
  box-shadow: 0 4px #c2b7a2;
  top: 1px;
  color: #fff;
}
.msp-preset-btn-108:active {
  box-shadow: 0 2px #c2b7a2;
  top: 3px;
}
.msp-preset-btn-109 {
  background-color: #50485b;
  color: #fff;
}
.msp-preset-btn-109:hover {
  background-color: #6a6176;
  color: #fff;
}
.msp-preset-btn-109:active {
  top: 1px;
}
.msp-preset-btn-110 {
  background-color: #50485b;
  color: #fff;
}
.msp-preset-btn-110:hover {
  background-color: #6a6176;
  color: #fff;
}
.msp-preset-btn-110:active {
  top: 1px;
}
.msp-preset-btn-111 {
  color: #50485b;
  border: solid 1px #50485b;
}
.msp-preset-btn-111:hover {
  border-color: #6a6176;
  color: #6a6176;
}
.msp-preset-btn-111:active {
  top: 1px;
}
.msp-preset-btn-112 {
  background-color: #50485b;
  color: #fff;
}
.msp-preset-btn-112:hover {
  background-color: #6a6176;
  color: #fff;
}
.msp-preset-btn-112:active {
  top: 1px;
}
.msp-preset-btn-113 {
  background-color: #50485b;
  color: #fff;
  box-shadow: 0 5px #412d5b;
}
.msp-preset-btn-113:hover {
  background-color: #6a6176;
  box-shadow: 0 4px #412d5b;
  top: 1px;
  color: #fff;
}
.msp-preset-btn-113:active {
  box-shadow: 0 2px #412d5b;
  top: 3px;
}
.msp-preset-btn-114 {
  background-color: #8d6dc4;
  color: #fff;
}
.msp-preset-btn-114:hover {
  background-color: #977cc4;
  color: #fff;
}
.msp-preset-btn-114:active {
  top: 1px;
}
.msp-preset-btn-115 {
  background-color: #8d6dc4;
  color: #fff;
}
.msp-preset-btn-115:hover {
  background-color: #977cc4;
  color: #fff;
}
.msp-preset-btn-115:active {
  top: 1px;
}
.msp-preset-btn-116 {
  color: #8d6dc4;
  border: solid 1px #8d6dc4;
}
.msp-preset-btn-116:hover {
  border-color: #977cc4;
  color: #977cc4;
}
.msp-preset-btn-116:active {
  top: 1px;
}
.msp-preset-btn-117 {
  background-color: #8d6dc4;
  color: #fff;
}
.msp-preset-btn-117:hover {
  background-color: #977cc4;
  color: #fff;
}
.msp-preset-btn-117:active {
  top: 1px;
}
.msp-preset-btn-118 {
  background-color: #8d6dc4;
  color: #fff;
  box-shadow: 0 5px #7c51c4;
}
.msp-preset-btn-118:hover {
  background-color: #977cc4;
  box-shadow: 0 4px #7c51c4;
  top: 1px;
  color: #fff;
}
.msp-preset-btn-118:active {
  box-shadow: 0 2px #7c51c4;
  top: 3px;
}
.msp-preset-btn-119 {
  background-color: #75d69c;
  color: #fff;
}
.msp-preset-btn-119:hover {
  background-color: #7de5a7;
  color: #fff;
}
.msp-preset-btn-119:active {
  top: 1px;
}
.msp-preset-btn-120 {
  background-color: #75d69c;
  color: #fff;
}
.msp-preset-btn-120:hover {
  background-color: #7de5a7;
  color: #fff;
}
.msp-preset-btn-120:active {
  top: 1px;
}
.msp-preset-btn-121 {
  color: #75d69c;
  border: solid 1px #75d69c;
}
.msp-preset-btn-121:hover {
  border-color: #7de5a7;
  color: #7de5a7;
}
.msp-preset-btn-121:active {
  top: 1px;
}
.msp-preset-btn-122 {
  background-color: #75d69c;
  color: #fff;
}
.msp-preset-btn-122:hover {
  background-color: #7de5a7;
  color: #fff;
}
.msp-preset-btn-122:active {
  top: 1px;
}
.msp-preset-btn-123 {
  background-color: #75d69c;
  color: #fff;
  box-shadow: 0 5px #41d67d;
}
.msp-preset-btn-123:hover {
  background-color: #7de5a7;
  box-shadow: 0 4px #41d67d;
  top: 1px;
  color: #fff;
}
.msp-preset-btn-123:active {
  box-shadow: 0 2px #41d67d;
  top: 3px;
}
.msp-preset-btn-124 {
  background-color: #222;
  color: #fff;
}
.msp-preset-btn-124:hover {
  background-color: #333;
  color: #fff;
}
.msp-preset-btn-124:active {
  top: 1px;
}
.msp-preset-btn-125 {
  background-color: #222;
  color: #fff;
}
.msp-preset-btn-125:hover {
  background-color: #333;
  color: #fff;
}
.msp-preset-btn-125:active {
  top: 1px;
}
.msp-preset-btn-126 {
  color: #222;
  border: solid 1px #222;
}
.msp-preset-btn-126:hover {
  border-color: #333;
  color: #333;
}
.msp-preset-btn-126:active {
  top: 1px;
}
.msp-preset-btn-127 {
  background-color: #222;
  color: #fff;
}
.msp-preset-btn-127:hover {
  background-color: #333;
  color: #fff;
}
.msp-preset-btn-127:active {
  top: 1px;
}
.msp-preset-btn-128 {
  background-color: #222;
  color: #fff;
  box-shadow: 0 5px #000;
}
.msp-preset-btn-128:hover {
  background-color: #333;
  box-shadow: 0 4px #000;
  top: 1px;
  color: #fff;
}
.msp-preset-btn-128:active {
  box-shadow: 0 2px #000;
  top: 3px;
}
.msp-preset-btn-129 {
  background-color: #dedede;
  color: #666;
}
.msp-preset-btn-129:hover {
  background-color: #d1d1d1;
  color: #666;
}
.msp-preset-btn-129:active {
  top: 1px;
}
.msp-preset-btn-130 {
  background-color: #dedede;
  color: #666;
}
.msp-preset-btn-130:hover {
  background-color: #d1d1d1;
  color: #666;
}
.msp-preset-btn-130:active {
  top: 1px;
}
.msp-preset-btn-131 {
  color: #dedede;
  border: solid 1px #dedede;
}
.msp-preset-btn-131:hover {
  border-color: #d1d1d1;
  color: #d1d1d1;
}
.msp-preset-btn-131:active {
  top: 1px;
}
.msp-preset-btn-132 {
  background-color: #dedede;
  color: #666;
}
.msp-preset-btn-132:hover {
  background-color: #d1d1d1;
  color: #666;
}
.msp-preset-btn-132:active {
  top: 1px;
}
.msp-preset-btn-133 {
  background-color: #dedede;
  color: #666;
  box-shadow: 0 5px #cacaca;
}
.msp-preset-btn-133:hover {
  background-color: #d1d1d1;
  color: #666;
  box-shadow: 0 4px #cacaca;
  top: 1px;
}
.msp-preset-btn-133:active {
  box-shadow: 0 2px #cacaca;
  top: 3px;
}
.msp-preset-btn-134 {
  background-color: #f7be68;
  color: #fff;
}
.msp-preset-btn-134:hover {
  background-color: #e9b362;
  color: #fff;
}
.msp-preset-btn-134:active {
  top: 1px;
}
.msp-preset-btn-135 {
  background-color: #f7be68;
  color: #fff;
}
.msp-preset-btn-135:hover {
  background-color: #e9b362;
  color: #fff;
}
.msp-preset-btn-135:active {
  top: 1px;
}
.msp-preset-btn-136 {
  color: #f7be68;
  border: solid 1px #f7be68;
}
.msp-preset-btn-136:hover {
  border-color: #e9b362;
  color: #e9b362;
}
.msp-preset-btn-136:active {
  top: 1px;
}
.msp-preset-btn-137 {
  background-color: #f7be68;
  color: #fff;
}
.msp-preset-btn-137:hover {
  background-color: #e9b362;
  color: #fff;
}
.msp-preset-btn-137:active {
  top: 1px;
}
.msp-preset-btn-138 {
  background-color: #f7be68;
  color: #fff;
  box-shadow: 0 5px #e7af59;
}
.msp-preset-btn-138:hover {
  background-color: #e9b362;
  box-shadow: 0 4px #e7af59;
  top: 1px;
  color: #fff;
}
.msp-preset-btn-138:active {
  box-shadow: 0 2px #e7af59;
  top: 3px;
}
.msp-preset-btn-139 {
  background-color: #5aa1e3;
  color: #fff;
}
.msp-preset-btn-139:hover {
  background-color: #5faaef;
  color: #fff;
}
.msp-preset-btn-139:active {
  top: 1px;
}
.msp-preset-btn-140 {
  background-color: #5aa1e3;
  color: #fff;
}
.msp-preset-btn-140:hover {
  background-color: #5faaef;
  color: #fff;
}
.msp-preset-btn-140:active {
  top: 1px;
}
.msp-preset-btn-141 {
  color: #5aa1e3;
  border: solid 1px #5aa1e3;
}
.msp-preset-btn-141:hover {
  border-color: #5faaef;
  color: #5faaef;
}
.msp-preset-btn-141:active {
  top: 1px;
}
.msp-preset-btn-142 {
  background-color: #5aa1e3;
  color: #fff;
}
.msp-preset-btn-142:hover {
  background-color: #5faaef;
  color: #fff;
}
.msp-preset-btn-142:active {
  top: 1px;
}
.msp-preset-btn-143 {
  background-color: #5aa1e3;
  color: #fff;
  box-shadow: 0 5px #4c87bf;
}
.msp-preset-btn-143:hover {
  background-color: #5faaef;
  box-shadow: 0 4px #4c87bf;
  top: 1px;
  color: #fff;
}
.msp-preset-btn-143:active {
  box-shadow: 0 2px #4c87bf;
  top: 3px;
}
.msp-preset-btn-144 {
  background-color: #6dab3c;
  color: #fff;
}
.msp-preset-btn-144:hover {
  background-color: #76b941;
  color: #fff;
}
.msp-preset-btn-144:active {
  top: 1px;
}
.msp-preset-btn-145 {
  background-color: #6dab3c;
  color: #fff;
}
.msp-preset-btn-145:hover {
  background-color: #76b941;
  color: #fff;
}
.msp-preset-btn-145:active {
  top: 1px;
}
.msp-preset-btn-146 {
  color: #6dab3c;
  border: solid 1px #6dab3c;
}
.msp-preset-btn-146:hover {
  border-color: #76b941;
  color: #76b941;
}
.msp-preset-btn-146:active {
  top: 1px;
}
.msp-preset-btn-147 {
  background-color: #6dab3c;
  color: #fff;
}
.msp-preset-btn-147:hover {
  background-color: #76b941;
  color: #fff;
}
.msp-preset-btn-147:active {
  top: 1px;
}
.msp-preset-btn-148 {
  background-color: #6dab3c;
  color: #fff;
  box-shadow: 0 5px #5e9334;
}
.msp-preset-btn-148:hover {
  background-color: #76b941;
  box-shadow: 0 4px #5e9334;
  top: 1px;
  color: #fff;
}
.msp-preset-btn-148:active {
  box-shadow: 0 2px #5e9334;
  top: 3px;
}
.msp-preset-btn-149 {
  background-color: #f4524d;
  color: #fff;
}
.msp-preset-btn-149:hover {
  background-color: #e04b47;
  color: #fff;
}
.msp-preset-btn-149:active {
  top: 1px;
}
.msp-preset-btn-150 {
  background-color: #f4524d;
  color: #fff;
}
.msp-preset-btn-150:hover {
  background-color: #e04b47;
  color: #fff;
}
.msp-preset-btn-150:active {
  top: 1px;
}
.msp-preset-btn-151 {
  color: #f4524d;
  border: solid 1px #f4524d;
}
.msp-preset-btn-151:hover {
  border-color: #e04b47;
  color: #e04b47;
}
.msp-preset-btn-151:active {
  top: 1px;
}
.msp-preset-btn-152 {
  background-color: #f4524d;
  color: #fff;
}
.msp-preset-btn-152:hover {
  background-color: #e04b47;
  color: #fff;
}
.msp-preset-btn-152:active {
  top: 1px;
}
.msp-preset-btn-153 {
  background-color: #f4524d;
  color: #fff;
  box-shadow: 0 5px #cb4440;
}
.msp-preset-btn-153:hover {
  background-color: #e04b47;
  box-shadow: 0 4px #cb4440;
  top: 1px;
  color: #fff;
}
.msp-preset-btn-153:active {
  box-shadow: 0 2px #cb4440;
  top: 3px;
}
.msp-preset-btn-154 {
  background-color: #f79468;
  color: #fff;
}
.msp-preset-btn-154:hover {
  background-color: #e78a61;
  color: #fff;
}
.msp-preset-btn-154:active {
  top: 1px;
}
.msp-preset-btn-155 {
  background-color: #f79468;
  color: #fff;
}
.msp-preset-btn-155:hover {
  background-color: #e78a61;
  color: #fff;
}
.msp-preset-btn-155:active {
  top: 1px;
}
.msp-preset-btn-156 {
  color: #f79468;
  border: solid 1px #f79468;
}
.msp-preset-btn-156:hover {
  border-color: #e78a61;
  color: #e78a61;
}
.msp-preset-btn-156:active {
  top: 1px;
}
.msp-preset-btn-157 {
  background-color: #f79468;
  color: #fff;
}
.msp-preset-btn-157:hover {
  background-color: #e78a61;
  color: #fff;
}
.msp-preset-btn-157:active {
  top: 1px;
}
.msp-preset-btn-158 {
  background-color: #f79468;
  color: #fff;
  box-shadow: 0 5px #da835c;
}
.msp-preset-btn-158:hover {
  background-color: #e78a61;
  box-shadow: 0 4px #da835c;
  top: 1px;
  color: #fff;
}
.msp-preset-btn-158:active {
  box-shadow: 0 2px #da835c;
  top: 3px;
}
.msp-preset-btn-159 {
  background-color: #ecb338;
  color: #6d5b1c;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 18px 26px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.msp-preset-btn-159:hover {
  background-color: #ecb338;
  color: #6d5b1c;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 18px 26px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.msp-preset-btn-159:active {
}
.ms-parent-id-1 > .master-slider {
  background: #352c21;
}
.msp-cn-1-1 {
  font-family: 'Lato';
  font-weight: 300;
  font-size: 57px;
  line-height: normal;
  color: #ffffff;
}
.msp-cn-1-2 {
  font-family: 'Lato';
  font-weight: 300;
  font-size: 85px;
  line-height: normal;
  color: #ffffff;
}
.msp-cn-1-3 {
  font-family: 'Lato';
  font-weight: 900;
  font-size: 95px;
  line-height: normal;
  color: #ffffff;
}
.msp-cn-1-5 {
  font-family: 'Lato';
  font-weight: 300;
  font-size: 72px;
  line-height: normal;
  color: #2d2418;
}
.msp-cn-1-7 {
  font-family: 'Lato';
  font-weight: 900;
  font-size: 64px;
  letter-spacing: 3px;
  line-height: normal;
  color: #ffffff;
  text-transform: uppercase;
}
.msp-cn-1-9 {
  font-family: 'Lato';
  font-weight: 900;
  font-size: 64px;
  letter-spacing: 3px;
  line-height: normal;
  color: #2d2418;
  text-transform: uppercase;
}
.msp-cn-1-10 {
  background-color: #020202;
  padding-top: 10px;
  padding-right: 22px;
  padding-bottom: 10px;
  padding-left: 22px;
  border-radius: 3px;
  font-family: 'Lato';
  font-weight: 300;
  font-size: 47px;
  line-height: normal;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
}
.msp-cn-1-13 {
  font-family: 'Lato';
  font-weight: 900;
  font-size: 64px;
  letter-spacing: 4px;
  line-height: normal;
  color: #2d2418;
  text-transform: uppercase;
}
